import React from 'react'
import "../../css/bootstrap.scss"
import "./cookie-policy.scss"
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link } from 'react-router-dom'


function CookiePolicy() {

    return (<>
        <div className={`bootstrap-content cookie-policy-page`}>
            <div className="header-sec">
                <div className="bg-white">
                    <Header />
                </div>
            </div>
            <section className='section-padding'>
                <div className="container">
                    <div className="row justify-content-center mt-3 mt-lg-3">
                        <div className="col-12 col-lg-10 col-md-11 justify-content-start">
                            <h1 className='single-page-heading'>Cookie Policy</h1>
                            <div className="divider">
                              <div className="left"></div>
                              <div className="right"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-11 col-lg-7 col-md-9">
                            <div className="single-page-content">
                                <p>Effective Date: October 9th 2024</p>
                                <p>1. Introduction</p>
                                <p>At GoodReviews.co, we use cookies and similar tracking technologies to enhance your experience on our website. This Cookie Policy explains what cookies are, how we use them, how third-parties we may partner with may use cookies on the service, your choices regarding cookies, and further information about cookies.</p>
                                <p>2. What Are Cookies?</p>
                                <p>Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.</p>
                                <p>3. How GoodReviews.co Uses Cookies</p>
                                <p>When you use and access the Service, we may place a number of cookies files in your web browser. We use cookies for the following purposes:</p>
                                <ul>
                                    <li>Essential Cookies: We use cookies to remember information that changes the way the Service behaves or looks, such as a user's language preference on the Service.</li>
                                    <li>Account-Related Cookies: We use cookies to manage the signup process and general administration. These cookies will usually be deleted when you log out; however, in some cases, they may remain afterward to remember your site preferences when logged out.</li>
                                    <li>Analytics Cookies: We use cookies to help us analyze how our visitors use the website and to monitor website performance. This helps us provide a high-quality experience by customizing our offering and quickly identifying and fixing any issues that arise.</li>
                                </ul>
                                <p>4. Third-Party Cookies</p>
                                <p>In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the Service and deliver advertisements on and through the Service. These cookies are set by domains other than our own.</p>
                                <p>5. Your Choices Regarding Cookies</p>
                                <p>If you prefer to avoid the use of cookies on the website, you must first disable the use of cookies in your browser and then delete the cookies saved in your browser associated with this website. You may use this option for preventing the use of cookies at any time.</p>
                                <p>6. More Information About Cookies</p>
                                <p>To learn more about cookies and how to manage them, visit <Link to="https://www.allaboutcookies.org" target='_blank'>www.allaboutcookies.org</Link> or <Link to="https://www.youronlinechoices.com" target="_blank">www.youronlinechoices.com</Link>.</p>
                                <p>7. Changes to This Cookie Policy</p>
                                <p>We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page. You are advised to review this Cookie Policy periodically for any changes. Changes to this Cookie Policy are effective when they are posted on this page.</p>
                                <p>8. Contact Us</p>
                                <p>If you have any questions about our Cookie Policy, please contact us:</p>
                                <p>Email: <Link to="mailto:support@goodreviews.co" target='_blank'>support@goodreviews.co</Link></p>
                                <p>Website: <Link to="https://goodreviews.co">GoodReviews.co</Link></p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
          <Footer />
        </div>
    </>)
}

export default CookiePolicy
