import React from 'react'
import "../../css/bootstrap.scss"
import "./disclaimer.scss"
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link } from 'react-router-dom'


function Disclaimer() {

    return (<>
        <div className="bootstrap-content disclaimer-page">
            <div className="header-sec">
                <div className="bg-white">
                    <Header />
                </div>
            </div>
            <section className='section-padding'>
                <div className="container">
                    <div className="row justify-content-center mt-3 mt-lg-3">
                        <div className="col-12 col-lg-10 col-md-11 justify-content-start">
                            <h1 className='single-page-heading'>Disclaimer</h1>
                            <div className="divider">
                                <div className="left"></div>
                                <div className="right"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-11 col-lg-7 col-md-9">
                            <div className="single-page-content">
                                <p>1. General Information</p>
                                <p>The information provided by GoodReviews.co ("the Site") is for general informational purposes only. While we strive to keep the information accurate and up to date, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the Site or the information, products, services, or related graphics contained on the Site for any purpose.</p>
                                <p>2. Professional Advice</p>
                                <p>The content on GoodReviews.co does not constitute professional advice, and you should not rely solely on it. Always seek the advice of qualified professionals regarding any specific questions or concerns you may have.</p>
                                <p>3. Third-Party Links</p>
                                <p>Through this Site, you may be able to link to other websites that are not under the control of GoodReviews.co. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
                                <p>4. User-Generated Content</p>
                                <p>GoodReviews.co may contain user-generated content. We do not endorse or guarantee the accuracy of any user content and are not liable for any loss or damage arising from your reliance on such content.</p>
                                <p>5. Limitation of Liability</p>
                                <p>In no event will GoodReviews.co be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this Site.</p>
                                <p>6. Changes to the Disclaimer</p>
                                <p>We may update this Disclaimer from time to time. You are encouraged to review this Disclaimer periodically for any changes. Your continued use of the Site after any modifications to the Disclaimer will constitute your acknowledgment of the modifications and your consent to abide by and be bound by the modified Disclaimer.</p>
                                <p>7. Contact Information</p>
                                <p>If you have any questions about this Disclaimer, please contact us at <Link to="mailto:support@goodreviews.co" target="_blank">support@goodreviews.co</Link>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    </>)
}

export default Disclaimer
