import React from 'react';
import "./horizontalbarchart.scss"
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // required to register the components

const HorizontalBarChart = () => {
    const data = {
        labels: ['5 Stars', '4 Stars', '3 Stars', '2 Stars', '1 Stars'],
        datasets: [
            {
                label: 'Ratings',
                data: [30, 0, 0, 0, 0],
                backgroundColor: '#d4a44a',
            },
        ],
    };

    const options = {
        indexAxis: 'y',
        scales: {
            x: {
                beginAtZero: true,
                max: 30,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <div className='horizontal-bar-page'>
            <div className="barchart">
                <Bar data={data} options={options} />
            </div>
        </div>
    );
};

export default HorizontalBarChart;
