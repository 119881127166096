import React, { useEffect, useState } from 'react'
import "./settings.scss"
import { FaQuestionCircle } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { MdAddCircle } from 'react-icons/md'
import { getAccountChannels, getAccountData } from '../../../Api'

function Settings() {
    const navigate = useNavigate()
    const [userData, setUserData] = useState(null)
    const [usedInvites, setUsedInvites] = useState(null)
    const [accountChannels, setAccountChannels] = useState([])

    useEffect(() => {
        (async () => {
            let userInfo = JSON.parse(localStorage.getItem('userData') || '{}')
            setUserData(userInfo);
            let res = await getAccountData(userInfo.correl_id);
            if (res.status === 'S') {
                setUsedInvites(res.result_info.length)
            }
            let apiData = await getAccountChannels(userInfo.correl_id);
            if (apiData.status === 'S') {
                setAccountChannels(apiData.result_info);
            }
        })()
    }, [])

    return (
        <div className='settings-page'>
            <div className='headingcontainer'>
                <h5 className='heading mb-0'>Account Settings</h5>
                <FaQuestionCircle color='#E9C877' className='heading-icon' />
            </div>
            <p className='mb-0 settings-desc'>Change account settings & billing settings</p>
            <div className="row align-items-center">
                <div className="col-12 col-lg-6">
                    <div className="settings-card my-3">
                        <div className="text-start">
                            <h6 className='mb-0'>Account Email</h6>
                            <p className='card-desc'>{userData && userData.email}</p>
                            <h6 className='mb-2'>Change Password</h6>
                            <button className='btn btn-dark px-3 py-1 rounded-pill mb-2' onClick={() => { navigate('/grow/reset-password') }}>Change Password</button>
                        </div>
                    </div>
                    <div className="settings-card">
                        <div className="text-start">
                            <h6>Review Channels</h6>
                            {/* <h6 className='mb-2'>Google</h6>
                            <Link className='google-link' to='https://search.google.com/local/writereview?placeid=ChiJVVUJBGGryszRLViHIfUJqc4'>https://www.google.com/search?q=rivirtual&oq=rivirtual&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyBggBEEUYPDIGCAIQRRhBMg0IAxAuGK8BGMcBGIAEMgcIBBAAGIAEMgYIBRBFGEEyBggGEEUYPDIGCAcQRRhB0gEJMTI2NjNqMGo0qAIAsAIB&sourceid=chrome&ie=UTF-8#lrd=0x3bcb933f697df7b7:0x81be510ea5581b22,1</Link>
                            <div className="addchannelcontainer">
                                <h6 className='mb-1'>Add review channel</h6>
                                <MdAddCircle color='#E9C877' />
                            </div> */}
                            {accountChannels && accountChannels.length > 0 && accountChannels.map((channel) => <div key={'channel_id-' + channel.account_channel_id}>
                                <h6 className='mt-2 mb-1'>{channel.channel_name}</h6>
                                <Link className='google-link' target='_blank' to={channel.channel_url}>{channel.channel_url}</Link>
                            </div>)}
                            <div className="addchannelcontainer d-flex align-items-center">
                                <h6 className='mb-0'>Add review channel</h6><MdAddCircle className='pointer' onClick={() => { navigate('/grow/create-account-channel') }} color='#E9C877' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="settings-card my-3">
                        <h6 className='mb-0'>Membership</h6>
                        <div>
                            <button className='btn btn-blue rounded-pill my-2'>{userData && userData.plan}</button>
                        </div>
                        {/* <div>
                            <button className='btn btn-dark px-3 py-1 rounded-pill my-2'>Change Plan</button>
                        </div> */}
                        <p className='text-danger'>Cancel Membership</p>

                        <h6 className='mb-0'>Invites Sent</h6>
                        <p className='card-text'>{usedInvites ? usedInvites : 0}/{userData && userData.invites}</p>

                        {/* <h6 className='mb-0'>Billing Details</h6>
                        <p className='card-text mb-0'>1xGoodREVIEWS SOLO:$47 / M</p> */}
                        {/* <p className='card-text mb-0'>Renews at: {userData && userData.validity_end_date}</p>
                        <div>
                            <button className='btn btn-dark px-3 py-1 rounded-pill my-2'>Change Credit Card</button>
                        </div> */}
                    </div>

                </div>
            </div>


        </div>
    )
}

export default Settings
