import React from 'react'
import "../../css/bootstrap.scss"
import "./terms.scss"
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link } from 'react-router-dom'


function TermsAndConditions() {

    return (<>
        <div className={`bootstrap-content terms-conditions-page`}>
            <div className="header-sec">
                <div className="bg-white">
                    <Header />
                </div>
            </div>
            <section className='section-padding'>
                <div className="container">
                    <div className="row justify-content-center mt-3 mt-lg-3">
                        <div className="col-12 col-lg-10 justify-content-start">
                            <h1 className='single-page-heading'>Terms & Conditions</h1>
                            <div className="divider">
                              <div className="left"></div>
                              <div className="right"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-12 col-lg-7">
                      <div className="single-page-content">
                              <p>Effective Date:  October 9, 2024</p>

                              <p>These terms and conditions ("Agreement") apply to private individuals, 
                              businesses, and organizations (each a "Customer") making use of the 
                              GoodReviews.co review service ("GoodReviews Review Collection Service"), 
                              including but not limited to Customers’ trial use of the GoodReviews Review 
                              Collection Service.</p>

                              <p>1. Acceptance</p>

                              <p>The terms of use listed below (hereafter simply referred to as the 'terms') 
                              govern the use of our services (hereafter simply referred to as 'services'), 
                              including the contribution of review postings on the GoodReviews.co website 
                              (hereafter simply referred to as 'the website'). Under these 'terms', the words 
                              'we', 'us', and 'our' refer to GoodReviews.co. If you do not wish to accept the 
                              terms, you are not permitted to use the website or the services. By using 
                              either the website and/or services, you have confirmed your agreement to 
                              these terms.</p>

                              <p>2. User Responsibilities</p>

                              <p>2.1 Registered User</p>

                              <p>To get access to, and make full use of, the services, you must register as a 
                              user on the website. While registering, you must choose a password to use 
                              along with your email address when you log on to the website. The password 
                              is unique to your account and must not be shared or made available to 
                              others.</p>

                              <p>2.2 Unauthorized Access</p>

                              <p>We do not allow direct competitors access to our client dashboard. Access 
                              by or on behalf of competitors is illegal and unauthorized. If we find that 
                              access has been gained by a competitor, we reserve the right to charge an 
                              access fee of $100,000 USD per calendar month for access.</p>

                              <p>2.3 Contributions from Registered Users</p>

                              <p>All material, information, reviews, comments, and any other form of 
                              communication ("contributions") received from registered users via the 
                              website are regarded as non-confidential. Contributors are responsible for 
                              the legality of their contributions. We reserve the right to delete contributions 
                              at any time and without warning.</p>

                              <p>3. Rights</p>

                              <p>The Customer is classed as the data controller when sending invitations for 
                              reviews, while GoodReviews.co is the data processor.</p>

                              <p>4. Contract</p>

                              <p>4.1 Contract Length</p>

                              <p>You can cancel your subscription package with GoodReviews.co at any time, 
                              subject to a 30-day written notice of cancellation. Billing continues until we 
                              receive written confirmation.</p>

                              <p>4.2 Cancellation Notice</p>

                              <p>Notice must be given by email to <Link to="mailto:support@goodreviews.co">support@goodreviews.co</Link>. Your notice is not 
                              effective until we have received it.</p>

                              <p>5. Indemnity</p>

                              <p>Clients shall indemnify GoodReviews.co against any loss or damage suffered 
                              as a result of any third-party claim related to the use of any content provided 
                              by the Client.</p>

                              <p>6. Limitation of Liability</p>

                              <p>Neither party shall be liable for any loss of profits or revenues, loss of business 
                              opportunity, loss of goodwill or reputation, or any indirect, consequential loss 
                              or special damages. The aggregate liability of GoodReviews.co shall not 
                              exceed the sum of £100 or the amount of your annual subscription, whichever 
                              is greater.</p>

                              <p>7. Safeguarding Your Information</p>

                              <p>To protect your information, we use the latest SSL technology for secure 
                              transactions. Account passwords are encrypted and cannot be retrieved.</p>

                              <p>8. Personal Information</p>

                              <p>We collect user data when users register with GoodReviews.co. This data will 
                              not be passed to a third party without prior consent. Registered users can 
                              request information about their data or have it deleted.</p>

                              <p>9. User-Generated Content</p>

                              <p>The rights in any user-generated content created on the website are licensed 
                              to GoodReviews.co for its non-exclusive use. Reviewers are not entitled to 
                              compensation related to such content.</p>

                              <p>10. Disclaimer</p>

                              <p>GoodReviews.co does not produce or publish contributions on the website 
                              and cannot be held accountable for the reviews and comments made by 
                              users. We reserve the right to delete reviews we suspect are fraudulent.</p>

                              <p>11. Marketing & Promotional Announcements</p>

                              <p>We may identify you as a customer on our website, and reviews may be 
                              displayed on GoodReviews.co and other platforms.</p>

                              <p>12. Governing Law</p>

                              <p>This Agreement shall be governed by the laws of the State of Delaware. Any 
                              disputes arising from this Agreement shall be resolved in the courts of the 
                              State of Delawar</p>
                            </div>
                      </div>
                    </div>
                </div>

            </section>
          <Footer />
        </div>
    </>)
}

export default TermsAndConditions
