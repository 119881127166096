import React, { useState } from 'react'
import "../../css/bootstrap.scss"
import "./weblogin.scss"
import { Link, useNavigate } from 'react-router-dom'
import { login } from '../../../Api';
import Swal from 'sweetalert2';
import { useRole } from '../../../RoleContext';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { Helmet } from 'react-helmet-async';

function WebLogin() {
    const { role, setRole } = useRole()
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = async () => {
        Swal.fire({
            html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
            customClass: { popup: 'loader' },
            showConfirmButton: false,
            heightAuto: false
        })
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        if (!email) {
            Swal.fire({
                text: 'Email required!',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!emalval.test(email)) {
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!password) {
            Swal.fire({
                text: 'Password required!',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let data = {
                email: email.toLowerCase(),
                password
            }
            let apiRes = await login(data);
            console.log(apiRes)
            if (apiRes.status === "S") {
                Swal.close();
                localStorage.setItem('correl_id', apiRes.correl_id);
                localStorage.setItem('role', apiRes.role)
                setRole(apiRes.role)
                localStorage.setItem('userData', JSON.stringify(apiRes.result_info));
                if (apiRes.is_first_time_login === 'Y') {
                    navigate('/change-password')
                } else {
                    if (apiRes.role === 1) {
                        localStorage.clear();
                        Swal.fire({
                            text: 'Invalid Credentials',
                            icon: 'warning',
                            iconColor: '#000',
                            showConfirmButton: false,
                            heightAuto: false,
                            timer: 1500
                        })
                    } else {
                        if (localStorage.getItem('refreshToken')) {
                            navigate('/grow/dashboard')
                        } else {
                            navigate('/connect-google', { state: { email: email } })
                        }
                    }
                }
            } else if (apiRes.status === 'E' && apiRes.result_code === 404) {
                Swal.fire({
                    text: 'Invalid Credentials',
                    icon: 'warning',
                    iconColor: '#000',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500
                })
            } else if (apiRes.status === 'E' && apiRes.result_code === 401) {
                Swal.fire({
                    text: 'Invalid Credentials',
                    icon: 'warning',
                    iconColor: '#000',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500
                })
            } else {
                // Swal.fire({
                //     text: JSON.stringify(apiRes.error),
                //     icon: 'warning',
                //     iconColor: '#000',
                //     showConfirmButton: false,
                //     heightAuto: false,
                //     timer: 1500
                // })
                Swal.fire({
                    text: 'Server Error',
                    icon: 'warning',
                    iconColor: '#000',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 1500
                })
            }
        }
    }
    const handleKeyDown = (ev) => {
        if (ev.key === "Enter") {
            ev.preventDefault();
            handleSubmit();
        }
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     navigate('/account-verification', { state: { email } });
    // };
    return (<>
        <Helmet>
        <title>Good Reviews | America's #1 Choice for Reputation Excellence | Global Customer Feedback Platform for Business Growth</title>
        <meta name="description" content="Empower your business with Good Reviews, trusted by 10,000+ global companies. Capture, manage, and leverage customer reviews to boost credibility and drive growth. Join industry leaders like AWS, Microsoft, and Google in harnessing the power of authentic feedback." />
        <meta name="keywords" content="customer reviews, business growth, global feedback platform, reputation management, social proof, customer testimonials, review management, trusted by enterprise, AWS partner, Microsoft partner, Google partner" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.goodreviews.co" />
        <meta property="og:title" content="Good Reviews: Amplify Your Global Business with Customer Feedback" />
        <meta property="og:description" content="Join 10,000+ companies worldwide in leveraging authentic customer reviews to boost credibility and drive growth. Trusted by industry leaders like AWS, Microsoft, and Google. Start your journey to better reviews today!" />
        <meta property="og:image" content="https://www.goodreviews.co/logo.png" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.goodreviews.co" />
        <meta property="twitter:title" content="Good Reviews: Amplify Your Global Business with Customer Feedback" />
        <meta property="twitter:description" content="Join 10,000+ companies worldwide in leveraging authentic customer reviews to boost credibility and drive growth. Trusted by industry leaders like AWS, Microsoft, and Google. Start your journey to better reviews today!" />
        <meta property="twitter:image" content="https://www.goodreviews.co/logo.png" />

    </Helmet>
        <div className='bootstrap-content weblogin-page' key={'role-' + role}>
            <div className="row g-0 w-100 h-100" >
                <div className="col-6 d-none d-lg-block">
                    <div className="login-left">
                        <div className="ellipse"></div>
                        <h6 className='login-heading'>Tell Your Reviews </h6>
                        <h6 className='login-subheading'>We Make It Global</h6>
                        <div className="bg-color">
                            <div className="image-container">
                                <img src={require('../../assets/video-file.png')} alt="" />
                            </div>
                        </div>
                        <h6 className='login-desc mt-3'>Trusted By 10,000+ Global Companies of all sizes</h6>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/aws.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/microsoft.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/google-partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/katalon.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/ware.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/citrix.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/salesforce.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/norton.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/symantec.png')} alt="" className='logos' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 h-100">
                    <div className="login-right">
                        <div className="login-card">
                            <div className="text-center">
                                <img src={require('../../assets/logo.png')} alt="" className='logo-image pointer' onClick={() => { navigate('/') }}  />
                                <h6 className='login-welcometext'>Welcome to Good Reviews</h6>
                            </div>
                            <div className="text-start">
                                <div className="mb-3 text-start">
                                    <label for="exampleInputEmail1" className="form-label">Email</label>
                                    <input type="text"
                                        name="User_Email"
                                        className="form-control"
                                        aria-describedby="userEmail"
                                        placeholder='Enter your Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete='goodreviews'
                                    />
                                </div>
                                <div className="mb-3 text-start">
                                    <label for="exampleInputPassword1" className="form-label">Password</label>
                                    <div className="d-flex justify-content-between align-items-center pos-rel">
                                        <input type={(password === '' || showPassword) ? "text" : "password"}
                                            name="User_Password"
                                            className="form-control"
                                            aria-describedby="userPassword"
                                            placeholder='Enter your Password'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            autoComplete='goodreviews'
                                            onKeyDown={handleKeyDown}
                                        />
                                        <span
                                            className="input-icon eye-icon"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                        </span>
                                    </div>
                                </div>
                                <button className='text-center btn btn-black' onClick={() => { handleSubmit() }}>
                                    Login
                                </button>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <Link to='/pricing' className='text-decoration-none text-black me-3'>
                                    <h6 className='bottom-text'>
                                        Create Account
                                    </h6>
                                </Link>
                                <Link to='/forgot-password' className='text-decoration-none text-black me-3'>
                                    <h6 className='bottom-text'>
                                        Forgot Password
                                    </h6>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    </>)
}

export default WebLogin
