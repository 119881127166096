import React from 'react';

function GaugeChart({ value, max = 5, numberOfTicks = 40 }) {
    const percentage = (value / max) * 100;
    const rotation = (percentage / 100) * 180;

    const ticks = Array.from({ length: numberOfTicks }, (_, index) => {
        const tickRotation = (index / (numberOfTicks - 1)) * 180;
        const isActive = tickRotation <= rotation;
        return (
            <line
                key={index}
                x1="0"
                y1="-80"
                x2="0"
                y2="-100"
                transform={`rotate(${tickRotation})`}
                stroke={getTickColor(tickRotation / 180)}
                strokeWidth="4"
                strokeLinecap="round"
                opacity={isActive ? 1 : 0.3}
            />
        );
    });

    return (
        <div className='gaugechart-page'>
            <div className='gaugechart'>
                <svg viewBox="-110 -110 220 120">
                    <g transform="rotate(-90)">
                        {ticks}
                    </g>
                    <text x="0" y="0" textAnchor="middle" fontSize="24">
                        {value.toFixed(2)}
                    </text>
                </svg>
            </div>
        </div>
    );
}

function getTickColor(percentage) {
    const blue = [30, 144, 255];  // Dodger Blue
    const green = [152, 251, 152];  // Pale Green

    const r = Math.round(blue[0] + (green[0] - blue[0]) * percentage);
    const g = Math.round(blue[1] + (green[1] - blue[1]) * percentage);
    const b = Math.round(blue[2] + (green[2] - blue[2]) * percentage);

    return `rgb(${r}, ${g}, ${b})`;
}

export default GaugeChart;