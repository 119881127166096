import React, { useEffect, useState } from 'react'
import "../../css/bootstrap.scss"
import "./signin.scss"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment';
import Swal from 'sweetalert2';
import { registerAccount, userSubscription } from '../../../Api';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

function Signin() {
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email || '';
    const plan = location.state?.plan || 'Free';
    const subscriptionType = location.state?.subscriptionType || 'Monthly';
    const [showPassword, setShowPassword] = useState(false);

    const [formInfo, setFormInfo] = useState({
        company_name: '',
        email: email,
        first_name: '',
        last_name: '',
        // mobile: '',
        // website: '',
        invites: '',
        validity_start_date: '',
        validity_end_date: '',
        password: ''
    })
    const handleKeyDown = (ev) => {
        if (ev.key === "Enter") {
            ev.preventDefault();
            handleSubmit();
        }
    };
    const handleSubmit = async () => {
        console.log({ ...formInfo, plan, subscriptionType });

        // return;
        Swal.fire({
            html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
            customClass: { popup: 'loader' },
            showConfirmButton: false,
            heightAuto: false
        })
        if (!formInfo.company_name) {
            Swal.fire({
                text: 'Company name required!',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!formInfo.password) {
            Swal.fire({
                text: 'Password required!',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            // let data = {
            //     ...formInfo,
            //     plan,
            //     subscription_type: subscriptionType
            // }
            // let apiRes = await registerAccount(data);
            // if (apiRes.status === "S") {
            //     Swal.fire({
            //         text: 'Account created successfully',
            //         icon: 'success',
            //         showConfirmButton: false,
            //         heightAuto: false,
            //         timer: 2000
            //     }).then(() => {
            //         navigate('/account-verification', { state: { email, correl_id: apiRes.result_info.correl_id } })
            //     })
            // } else if (apiRes.status_code === '300') {
            //     Swal.fire({
            //         text: 'Email already exists',
            //         icon: 'warning',
            //         iconColor: '#000',
            //         showConfirmButton: false,
            //         heightAuto: false,
            //         timer: 2000
            //     })
            // } else {
            //     Swal.close()
            // }
            let param = subscriptionType === "Monthly" ? 'startup_monthly' : "test_yearly"
            let data = {
                plan,
                plan_type: subscriptionType,
                desiredParam: param
            }
            let apiRes = await userSubscription(data);
            if (apiRes.status === "S") {
                window.location.href = apiRes.result_info;
                // Swal.fire({
                //     text: 'Account created successfully',
                //     icon: 'success',
                //     showConfirmButton: false,
                //     heightAuto: false,
                //     timer: 2000
                // }).then(() => {
                //     navigate('/account-verification', { state: { email, correl_id: apiRes.result_info.correl_id } })
                // })
            } else if (apiRes.status_code === '300') {
                Swal.fire({
                    text: 'Email already exists',
                    icon: 'warning',
                    iconColor: '#000',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 2000
                })
            } else {
                Swal.close()
            }
        }
        // navigate('/account-verification', { state: { email } });
    };

    useEffect(() => {
        let startDate = new Date();
        let endDate = '';
        let invites = 0;
        if (plan === 'Free') {
            invites = 20
        } else if (plan === 'Start-up') {
            invites = 500
        } else if (plan === 'Professional') {
            invites = 1500
        }
        startDate = moment(startDate).format('YYYY-MM-DD');
        if (subscriptionType === 'Monthly') {
            endDate = moment(startDate, "YYYY-MM-DD").add(1, 'year');
            endDate = moment(endDate).format('YYYY-MM-DD');
        } else {
            invites = invites * 12;
            endDate = moment(startDate, "YYYY-MM-DD").add(1, 'month');
            endDate = moment(endDate).format('YYYY-MM-DD');
        }
        setFormInfo({ ...formInfo, invites: invites, validity_start_date: startDate, validity_end_date: endDate })
    }, [plan, subscriptionType])

    return (
        <div className='bootstrap-content signin-page'>
            {/* <div className="row justify-content-lg-start g-0">
                <div className="col-12">
                    <div className="header-text">
                        <h6 className='mb-0'>GOOD REVIEWS</h6>
                        <img src={require('../../assets/logo.png')} alt="" />
                    </div>
                </div>
            </div> */}
            <div className="row g-0 w-100 h-100" >
                <div className="col-12 col-lg-6 d-none d-lg-block">
                    {/* <div className="signin-left">
                        <div className="ellipse"></div>
                    </div> */}
                    <div className="signin-left">
                        <div className="ellipse"></div>
                        <h6 className='signin-heading'>Tell Your Reviews </h6>
                        <h6 className='signin-subheading'>We Make It Global</h6>
                        <div className="bg-color">
                            <div className="image-container">
                                <img src={require('../../assets/video-file.png')} alt="" />
                            </div>
                        </div>
                        <h6 className='login-desc mt-3'>Trusted By 10,000+ Global Companies of all sizes</h6>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/aws.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/microsoft.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/google-partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/katalon.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/ware.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/citrix.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/salesforce.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/norton.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/symantec.png')} alt="" className='logos' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="signin-right">
                        <div className="signin-card">
                            <h6 className='signin-card-heading'>Welcome to Good Reviews </h6>
                            <p className='signin-card-desc'>You're setting up an account for {email}</p>
                            <div className='row justify-content-start'>
                                <div className="col-12">
                                    <div className="mb-3 text-start">
                                        <label for="companyName" className="form-label">Company Name *</label>
                                        <input type="text" autoComplete='goodreviews' className="form-control" id="companyName" value={formInfo.company_name} onChange={(ev) => { setFormInfo({ ...formInfo, company_name: ev.target.value }) }} aria-describedby="company_name" />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="mb-3 text-start">
                                        <label for="firstName" className="form-label">First Name</label>
                                        <input type="text" autoComplete='goodreviews' className="form-control" id="firstName" value={formInfo.first_name} onChange={(ev) => { setFormInfo({ ...formInfo, first_name: ev.target.value }) }} aria-describedby="first_name" />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="mb-3 text-start">
                                        <label for="lastName" className="form-label">Last Name</label>
                                        <input type="text" name="LastName" autoComplete='goodreviews' className="form-control" id="lastName" value={formInfo.last_name} onChange={(ev) => { setFormInfo({ ...formInfo, last_name: ev.target.value }) }} aria-describedby="last_name" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3 text-start">
                                        <label for="password" className="form-label">Password *</label>
                                        <div className="d-flex justify-content-between align-items-center pos-rel">
                                            <input type={(formInfo.password === '' || showPassword) ? "text" : "password"} name="CreatePassword" autoComplete='goodreviews' className="form-control" id="password" value={formInfo.password}
                                                onChange={(ev) => { setFormInfo({ ...formInfo, password: ev.target.value }) }}
                                                aria-describedby="createpassword"
                                                onKeyDown={handleKeyDown} />
                                            <span
                                                className="input-icon eye-icon"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="my-3 text-start" onClick={() => { handleSubmit() }}>
                                        <button className='btn btn-black'>Create Account</button>
                                    </div>
                                    <Link to='/account-registration' state={{ plan, subscriptionType }} className='text-decoration-none text-black'>
                                        <p className='back-text text-black'>Back</p>
                                    </Link>
                                </div>
                                <div className="col-12">
                                    <h6 className='bottom-text'>By signing up to the Good Reviews  platform you understand and agree with our <br />
                                        <span>Customer Terms of Service </span> and  <span>Privacy Policy</span></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signin
