import React, { useEffect, useState } from 'react'
import './layout.scss'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { MdClose, MdCropLandscape, MdDashboard, MdHelp, MdKeyboardArrowDown, MdLock, MdMenu, MdOutlineGroup, MdOutlinePowerSettingsNew, MdPeople, MdPerson, MdSend, MdSettings, MdStars } from 'react-icons/md'
import { IoHelpOutline } from 'react-icons/io5';


function Layout() {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const [role, setRole] = useState(null);

    const [isOpen, setIsOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const toggleSidebar = () => {
        if (window.innerWidth < 992) {
            setIsSidebarOpen(!isSidebarOpen)
        } else {
            setIsSidebarOpen(!isSidebarOpen)
        }
    }
    const closeSidebar = () => {
        if (window.innerWidth < 992) {
            setIsSidebarOpen(false);
        }
    };

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        let userRole = JSON.parse(localStorage.getItem('role'));
        console.log(userData);
        if (!userRole) {
            navigate('/grow')
        } else {
            setRole(userRole);
        };
    }, [navigate]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 992) {
                setIsSidebarOpen(true);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className='bootstrap-content'>
            <div className='d-flex h-100'>
                <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                    <div className="logoCont">
                        {/* <img src={require('../../../assets/logo-final.jpeg')} alt='' /> */}
                        <div className="d-flex align-items-center">
                            {/* <p className="logo-heading mb-0 me-lg-2">Good Reviews</p> */}
                            <img src={require('../../website/assets/logo.png')} alt="Logo" className="logo" />
                        </div>
                    </div>
                    <div className='d-block d-lg-none mb-2'>
                        <MdClose className='close-icon' onClick={closeSidebar} />
                    </div>
                    <div className="menuCont">
                        {role === 1 && (<div className='sidebarContainer'>
                            <Link to='/empower/create-account' className='text-decoration-none  text-black'>
                                <div className={pathname === '/empower/create-account' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                    <MdPerson fontSize={'20px'} className='sidebar-icon' />
                                    <h5 className='mb-0'>Create Account</h5>
                                </div>
                            </Link>
                            <Link to='/empower/manage-accounts' className='text-decoration-none'>
                                <div className={pathname === '/empower/manage-accounts' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                    <MdPeople fontSize={'20px'} className='sidebar-icon' />
                                    <h5 className='mb-0'>Manage Accounts</h5>
                                </div>
                            </Link>
                            <Link to='/empower/create-channel' className='text-decoration-none'>
                                <div className={pathname === '/empower/create-channel' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                    <MdCropLandscape fontSize={'20px'} className='sidebar-icon' />
                                    <h5 className='mb-0'>Create Channel</h5>
                                </div>
                            </Link>
                            <Link to='/empower/manage-channels' className='text-decoration-none'>
                                <div className={pathname === '/empower/manage-channels' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                    <MdCropLandscape fontSize={'20px'} className='sidebar-icon' />
                                    <h5 className='mb-0'>Manage Channels</h5>
                                </div>
                            </Link>
                            <Link to='/empower/masterdata' className='text-decoration-none'>
                                <div className={pathname === '/empower/masterdata' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                    <MdOutlineGroup fontSize={'20px'} className='sidebar-icon' />
                                    <h5 className='mb-0'>Masterdata</h5>
                                </div>
                            </Link>
                            <Link to='/empower/reset-password' className='text-decoration-none'>
                                <div className={pathname === '/empower/reset-password' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                    <MdLock fontSize={'20px'} className='sidebar-icon' />
                                    <h5 className='mb-0'>Reset Password</h5>
                                </div>
                            </Link>
                            <div className="sidebarcontent" onClick={() => {
                                localStorage.clear();
                                navigate('/empower');
                            }}>
                                <MdOutlinePowerSettingsNew fontSize={'20px'} className='sidebar-icon' />
                                <h5 className='mb-0'>Sign out</h5>
                            </div>
                        </div>)}
                        {role === 2 && (
                            <div className='sidebarContainer'>
                                <Link to='/grow/dashboard' className='text-decoration-none  text-black'>
                                    <div className={pathname === '/grow/dashboard' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                        <MdDashboard fontSize={'20px'} className='sidebar-icon' />
                                        <h5 className='mb-0'>Dashboard</h5>
                                    </div>
                                </Link>
                                <div className={`invitations-dropdown ${isOpen ? 'open' : ''}`}>
                                    <div className="sidebarcontent-invites" onClick={toggleDropdown}>
                                        <div className="sidebarcontent">
                                            <MdSend fontSize={'20px'} className={`sidebar-icon-invites ${isOpen ? 'rotated' : ''}`} />
                                            <h5 className='mb-0'>Invitations</h5>
                                        </div>
                                        <MdKeyboardArrowDown
                                            className={`dropdown-arrow me-3 ${isOpen ? 'open' : ''}`}
                                            fontSize={'20px'}
                                        />
                                    </div>
                                    {isOpen && (
                                        <div className="dropdown-content">
                                            <Link to='/grow/single-invite' className='text-decoration-none  text-black'>
                                                <div className={pathname === '/grow/single-invite' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                                    <h5 className='mb-0'>Single Invite</h5>
                                                    {/* <MdOutlineMailOutline fontSize={'18px'} className='sidebar-icon' /> */}
                                                </div>
                                            </Link>
                                            <Link to='/grow/multiple-invites' className='text-decoration-none  text-black'>
                                                <div className={pathname === '/grow/multiple-invites' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                                    <h5 className='mb-0'>Multiple Invites</h5>
                                                    {/* <MdOutlineMailOutline fontSize={'18px'} className='sidebar-icon' /> */}
                                                </div>
                                            </Link>
                                            {/* <Link to='/grow/invite-settings' className='text-decoration-none  text-black'>
                                                <div className={pathname === '/grow/invite-settings' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                                    <h5 className='mb-0'>Invite Settings</h5>
                                                </div>
                                            </Link> */}
                                        </div>
                                    )}
                                </div>
                                <Link to='/grow/recipients' className='text-decoration-none'>
                                    <div className={pathname === '/grow/recipients' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                        <MdOutlineGroup fontSize={'20px'} className='sidebar-icon' />
                                        <h5 className='mb-0'>Recipients</h5>
                                    </div>
                                </Link>
                                <Link to='/grow/manage-account-channels' className='text-decoration-none'>
                                    <div className={pathname === '/grow/manage-account-channels' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                        <MdCropLandscape fontSize={'20px'} className='sidebar-icon' />
                                        <h5 className='mb-0'>Channels</h5>
                                    </div>
                                </Link>
                                <Link to='/grow/reviews' className='text-decoration-none'>
                                    <div className={pathname === '/grow/reviews' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                        <MdStars fontSize={'20px'} className='sidebar-icon' />
                                        <h5 className='mb-0'>Reviews</h5>
                                    </div>
                                </Link>
                                {/* <Link to='/grow/business-settings' className='text-decoration-none'>
                                    <div className={pathname === '/grow/business-settings' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                        <MdBusiness fontSize={'20px'} className='sidebar-icon' />
                                        <h5 className='mb-0'>Business Settings</h5>
                                    </div>
                                </Link> */}
                                {/* <Link to='/grow/reporting' className='text-decoration-none'>
                                    <div className={pathname === '/grow/reporting' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                        <MdReport fontSize={'20px'} className='sidebar-icon' />
                                        <h5 className='mb-0'>Reporting</h5>
                                    </div>
                                </Link> */}
                                <hr />
                                <Link to='/grow/account-settings' className='text-decoration-none'>
                                    <div className={pathname === '/grow/account-settings' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                        <MdSettings fontSize={'20px'} className='sidebar-icon' />
                                        <h5 className='mb-0'>Settings</h5>
                                    </div>
                                </Link>
                                <Link to='/grow/support' className='text-decoration-none'>
                                    <div className={pathname === '/grow/support' ? 'sidebarcontent active' : 'sidebarcontent'}>
                                        <MdHelp fontSize={'20px'} className='sidebar-icon' />
                                        <h5 className='mb-0'>Support</h5>
                                    </div>
                                </Link>
                                <div className="sidebarcontent" onClick={() => {
                                    localStorage.clear();
                                    navigate('/grow');
                                }}>
                                    <MdOutlinePowerSettingsNew fontSize={'20px'} className='sidebar-icon' />
                                    <h5 className='mb-0'>Sign out</h5>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='mainContent'>
                    <div className='header'>
                        <div className='d-flex align-items-center'>
                            <MdMenu fontSize={28} onClick={() => { toggleSidebar() }} />
                            {!isSidebarOpen && <div className="logoCont">
                                <div className="d-flex align-items-center">
                                    <p className="logo-heading mb-0 me-lg-2">Good Reviews</p>
                                    <img src={require('../../website/assets/logo.png')} alt="Logo" className="logo" />
                                </div>
                            </div>}
                        </div>
                        {role === 2 && <div className='d-flex align-items-center'>
                            {/* <button className='btn btn-dark rounded-pill px-lg-4 me-3 d-none d-lg-block' onClick={() => { navigate('/grow/send-invites') }}>Send Invites</button> */}
                            <button className='btn btn-icon bg-dark text-white' onClick={() => { navigate('/grow/support') }}><IoHelpOutline size={24} /></button>
                        </div>}
                    </div>
                    <div className="routeContainer">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Layout