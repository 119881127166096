import React, { useEffect, useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
function ConnectGoogle() {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null)

    const handleLoginSuccess = async (tokenResponse) => {
      const { code } = tokenResponse;
  
      // Exchange the code for tokens at the backend
      try {
        const res = await axios.post('https://goodreviews.co/api/google-login', { code });
        console.log(res, 'loginData')
        const { idToken, accessToken, refreshToken, user } = res.data;
  
        // Store tokens and user information in local storage
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('user', JSON.stringify(user));
        navigate('/grow/dashboard')
      } catch (error) {
        console.error('Failed to login with Google', error);
      }
    };

    const login = useGoogleLogin({
      onSuccess: handleLoginSuccess,
      onError: () => console.log('Login Failed'),
      flow: 'auth-code',
    });

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('userData') || '{}');
        setUserInfo(userData)
    }, [])

    return (
        <div className='bootstrap-content weblogin-page'>
            <div className="row g-0 w-100 h-100" >
                <div className="col-6 d-none d-lg-block">
                    <div className="login-left">
                        <div className="ellipse"></div>
                        <h6 className='login-heading'>Tell Your Reviews </h6>
                        <h6 className='login-subheading'>We Make It Global</h6>
                        <div className="bg-color">
                            <div className="image-container">
                                <img src={require('../../assets/video-file.png')} alt="" />
                            </div>
                        </div>
                        <h6 className='login-desc mt-3'>Trusted By 10,000+ Global Companies of all sizes</h6>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/aws.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/microsoft.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/google-partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/katalon.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/ware.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/citrix.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/salesforce.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/norton.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/symantec.png')} alt="" className='logos' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 h-100">
                    <div className="login-right">
                        <div className="login-card">
                            <div className="text-center">
                                <img src={require('../../assets/logo.png')} alt="" className='logo-image' />
                                <h6 className='login-welcometext'><strong>Hi {userInfo && userInfo.first_name}</strong></h6>
                                <p>To make the most out of our platform, <br />
                                    <strong>Please connect your Google Business Account</strong>
                                </p>
                            </div>
                            <div className="text-start">
                                <button className='text-center btn btn-white' onClick={() => {login()}}>
                                  <img src={require('../../assets/google-icon.png')} style={{height: '35px', marginRight: '10px'}} alt="" />
                                    Connect to Google
                                </button>
                            </div>
                            <div>
                                <p className='text-center mb-0 pointer' onClick={() => {navigate('/grow/dashboard')}}>
                                    Skip for now
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ConnectGoogle