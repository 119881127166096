import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { changePassword } from "../../Api";
import { useNavigate } from "react-router-dom";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

function ResetPassword() {

  const navigate = useNavigate();
  const [userData, setuserData] = useState(null)
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleSubmit = async () => {
    Swal.fire({
      html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
      customClass: { popup: 'loader' },
      showConfirmButton: false,
      heightAuto: false
    })
    if (!oldPassword) {
      Swal.fire({
        text: 'Old password required!',
        showConfirmButton: false,
        icon: 'warning',
        iconColor: '#000',
        timer: 2000,
      })
      return;
    } else if (!newPassword) {
      Swal.fire({
        text: 'New password required!',
        showConfirmButton: false,
        icon: 'warning',
        iconColor: '#000',
        timer: 2000,
      })
      return;
    } else if (oldPassword === newPassword) {
      Swal.fire({
        text: 'New password should not match with old password!',
        showConfirmButton: false,
        icon: 'warning',
        iconColor: '#000',
        timer: 2000,
      })
      return;
    } else if (!confirmPassword) {
      Swal.fire({
        text: 'Confirm password required!',
        showConfirmButton: false,
        icon: 'warning',
        iconColor: '#000',
        timer: 2000,
      })
      return;
    } else if (confirmPassword !== newPassword) {
      Swal.fire({
        text: 'New password should match with confirm password!',
        showConfirmButton: false,
        icon: 'warning',
        iconColor: '#000',
        timer: 2000,
      })
      return;
    } else {
      let data = {
        correl_id: userData.correl_id,
        oldPassword,
        newPassword,
      }
      let apiRes = await changePassword(data);
      console.log(apiRes)
      if (apiRes.status === "S") {
        Swal.fire({
          text: 'Your password changed successfully. Redirecting to login',
          icon: 'success',
          showConfirmButton: false,
          heightAuto: false,
          timer: 2000
        }).then(() => {
          if (userData.account_code === 'prod-owner') {
            navigate('/empower')
          } else {
            navigate('/grow')
          }
        })
      } else if (apiRes.status === 'E' && apiRes.result_code === 404) {
        Swal.fire({
          text: 'User not found',
          showConfirmButton: false,
          icon: 'warning',
          iconColor: '#000',
          timer: 2000,
        })
      } else if (apiRes.status === 'E' && apiRes.result_code === 401) {
        Swal.fire({
          text: 'Invalid Old password',
          showConfirmButton: false,
          icon: 'warning',
          iconColor: '#000',
          timer: 2000,
        })
      } else {
        Swal.close();
      }
    }
  }

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem('userData') || '{}');
    setuserData(userInfo)
  }, [])

  return (
    <div className="bootstrap-content custom-page">
      <h5 className='heading mb-4'>{userData && userData.account_code === 'prod-owner' ? 'Reset Password' : 'Change Password'}</h5>
      <div className="ui-card">
        <div className="form-group mb-3">
          <div className="d-flex justify-content-between align-items-center pos-rel">
            <input
              type={showPassword ? "text" : 'password'}
              className="w-100"
              name="userpassword"
              autoComplete="off"
              placeholder="Enter old Password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <span
              className="input-icon eye-icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
            </span>
          </div>

        </div>
        <div className="form-group mb-3">
          <div className="d-flex justify-content-between align-items-center pos-rel">
            <input
              type={showPassword1 ? "text" : 'password'}
              className="w-100"
              name="userpassword"
              autoComplete="off"
              placeholder="Enter new Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <span
              className="input-icon eye-icon"
              onClick={() => setShowPassword1(!showPassword1)}
            >
              {showPassword1 ? <MdVisibility /> : <MdVisibilityOff />}
            </span>
          </div>

        </div>
        <div className="form-group mb-3">
          <div className="d-flex justify-content-between align-items-center pos-rel">
            <input
              type={showPassword2 ? "text" : 'password'}
              className="w-100"
              name="userpassword"
              autoComplete="off"
              placeholder="Re-enter new Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <span
              className="input-icon eye-icon"
              onClick={() => setShowPassword2(!showPassword2)}
            >
              {showPassword2 ? <MdVisibility /> : <MdVisibilityOff />}
            </span>
          </div>

        </div>
        <div className="text-center">
          <button
            className="btn btn-dark px-4"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </button>
          {(userData && userData.account_code !== 'prod-owner') &&  <button
            className="btn btn-dark px-4 ms-3"
            onClick={() => {
              navigate('/grow/account-settings')
            }}
          >
            Go Back
          </button>}
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
