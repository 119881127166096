import React, { useEffect, useRef, useState } from "react";
import "../sendinvites.scss";
import { getAccountChannels, sendMultipeInvites } from "../../../../Api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function MultipleInvites() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [channels, setChannels] = useState(null);
  const [content, setContent] = useState({ account_channel_id: '', channel_name: '' });

  const formatFileSize = (size) => {
    if (size === 0) return '0 Bytes';
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(1) + ' ' + units[i];
  };

  // Bulk
  const fileRef = useRef(null);
  const [file, setFile] = useState(null);
  const fileSize = file && formatFileSize(file.size);

  const handleFile = (ev) => {
    let newFile = ev.target.files[0];
    let fileName = newFile.name.split(".");
    let extension = fileName.pop();
    if (extension === "csv" || extension === "CSV") {
      setFile(ev.target.files[0]);
    } else {
      Swal.fire({
        text: "Invalid file format, Only .csv files are allowed",
        icon: "warning",
        iconColor: "#000",
        showConfirmButton: false,
        timer: 2000,
        heightAuto: false,
      });
    }
  };
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const submit = async () => {
    Swal.fire({
      html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
      customClass: { popup: 'loader' },
      showConfirmButton: false,
      heightAuto: false
    })
    if (!content.account_channel_id) {
      Swal.fire({
        text: 'Please select a Channel!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    } else if (!file) {
      Swal.fire({
        text: 'Please upload a csv file!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    } else {
      let userData = JSON.parse(localStorage.getItem('userData') || '{}');
      let formData = new FormData();
      formData.append('account_correl_id', userData.correl_id)
      formData.append('account_name', userData.company_name)
      formData.append('account_channel_id', content.account_channel_id)
      formData.append('channel_name', content.channel_name)
      formData.append('csvFile', file)
      let res = await sendMultipeInvites(formData);
      if (res.status === 'S') {
        Swal.fire({
          text: 'Success',
          icon: 'success',
          showConfirmButton: false,
          timer: 2000,
          heightAuto: false
        }).then(() => {
          navigate('/grow/recipients')
        })
      } else if (res.result_code === 203) {
        Swal.fire({
          text: (res && res.message) ? res.message : 'Limit Exceeded',
          icon: 'warning',
          iconColor: '#000',
          showConfirmButton: false,
          timer: 2000,
          heightAuto: false
        })
      } else {
        Swal.close()
      }
    }
  }

  useEffect(() => {
    (async () => {
      let userData = JSON.parse(localStorage.getItem('userData') || '{}');
      setUserInfo(userData);
      let res = await getAccountChannels(userData.correl_id);
      if (res.status === 'S') {
        setChannels(res.result_info)
      }
    })()
  }, []);

  return (
    <div className="invites-page">
      <div className="invites-card d-flex flex-column justify-content-center align-items-center">
        <div className="width-50 mx-auto">
          <h2>Multiple Email Invites</h2>
          <p className="mb-3" style={{ fontSize: "1rem" }}>
            Upload a .csv file
          </p>
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="form-group w-100 mb-3">
                <select
                  name="channel"
                  className="w-100 form-select mx-auto"
                  placeholder="Channel"
                  onChange={(ev) => {
                    let val = ev.target.value;
                    let selectedChannel = (val && channels && channels.length > 0) ? channels.filter((channel) => (channel.account_channel_id === +val)) : [{ channel_name: '' }];
                    console.log(selectedChannel)
                    setContent({ account_channel_id: ev.target.value, channel_name: selectedChannel[0].channel_name })
                  }}
                >
                  <option value="">-- Select Channel --</option>
                  {channels &&
                    channels.map((channel) => (
                      <option
                        key={channel.account_channel_id}
                        value={channel.account_channel_id}
                      >
                        {channel.channel_name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col-12 mb-3">
              <input type="file" ref={fileRef} hidden onChange={handleFile} />
              <div
                className="p-4 card" style={{ borderStyle: 'dashed', borderWidth: '5px', cursor: 'pointer' }}
                onClick={() => {
                  fileRef.current.click();
                }}
              >
                {!file && <h3>Choose a File</h3>}
                {file && <p className="mb-1">{file.name}</p>}
                {file && <p>File Size: {fileSize}</p>}
              </div>
            </div>

          </div>
          <label className="text-gray confirm-msg">
            <input
              type="checkbox"
              onChange={handleCheckboxChange}
              name=""
              id="confirm-msg"
            />{" "}
            I certify that all recipients have opted in to receive these
            communications, <br /> I further certify that I am an authorized
            representative of {userInfo && userInfo.company_name ? userInfo.company_name : ''}.
          </label>
          <div className="text-center">
            <button
              className="btn btn-dark rounded-pill px-4 mt-4" disabled={!isChecked}
              onClick={() => {
                submit();
              }}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultipleInvites;
