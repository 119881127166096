import React, { useEffect, useState } from 'react'
import "./create.scss"
import Swal from 'sweetalert2';
import { createAccountChannelApi, getChannels } from '../../../../Api';
import { useNavigate } from 'react-router-dom';

function CreateAccountChannel() {
  const navigate = useNavigate();
  const [channelInfo, setChannelInfo] = useState({ channel_name: '', channel_id: '', channel_url: '' })
  const [channels, setChannels] = useState(null)
  const [userInfo, setUserInfo] = useState(null)

  // const submit = async () => {
  //   if (!channelInfo.channel_url) {
  //     Swal.fire({
  //       text: 'Channel url is required',
  //       icon: 'warning',
  //       showConfirmButton: false,
  //       heightAuto: false,
  //       timer: 2000
  //     })
  //     return;
  //   }
  //   else if (!validateUrl(channelInfo.channel_url)) {
  //     Swal.fire({
  //       text: 'Please Enter a Valid Url',
  //       icon: 'warning',
  //       showConfirmButton: false,
  //       heightAuto: false,
  //       timer: 2000
  //     })
  //   } else {
  //     let data = {
  //       account_correl_id: userInfo.correl_id,
  //       channel_name: channelInfo.channel_name,
  //       channel_id: channelInfo.channel_id,
  //       channel_url: channelInfo.channel_url,
  //     };
  //     let apiRes = await createAccountChannelApi(data);
  //     if (apiRes.status === "S") {
  //       Swal.fire({
  //         text: 'Channel created successfully',
  //         icon: 'success',
  //         showConfirmButton: false,
  //         heightAuto: false,
  //         timer: 2000
  //       }).then(() => {
  //         navigate('/grow/manage-account-channels')
  //       })
  //     } else if (apiRes.result_code === 201) {
  //       Swal.fire({
  //         text: 'Channel with same name already exists!',
  //         icon: 'warning',
  //         iconColor: '#000',
  //         showConfirmButton: false,
  //         heightAuto: false,
  //         timer: 2000
  //       })
  //     }
  //   }

  // }
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const submit = async () => {
    if (!channelInfo.channel_id) {
      Swal.fire({
        text: 'Channel is required',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 2000
      });
    }else if (!channelInfo.channel_url) {
      Swal.fire({
        text: 'Channel URL is required',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 2000
      });
    }else if (!isValidUrl(channelInfo.channel_url)) {
      Swal.fire({
        text: 'Please enter a valid URL',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 2000
      });
    }else{
      let data = {
        account_correl_id: userInfo.correl_id,
        channel_name: channelInfo.channel_name,
        channel_id: channelInfo.channel_id,
        channel_url: channelInfo.channel_url,
      };
  
      let apiRes = await createAccountChannelApi(data);
      if (apiRes.status === "S") {
        Swal.fire({
          text: 'Channel created successfully',
          icon: 'success',
          showConfirmButton: false,
          heightAuto: false,
          timer: 2000
        }).then(() => {
          navigate('/grow/manage-account-channels');
        });
      } else if (apiRes.result_code === 201) {
        Swal.fire({
          text: 'Channel with the same name already exists!',
          icon: 'warning',
          iconColor: '#000',
          showConfirmButton: false,
          heightAuto: false,
          timer: 2000
        });
      }
    }

  };

  useEffect(() => {
    (async () => {
      let userData = JSON.parse(localStorage.getItem('userData') || '{}');
      setUserInfo(userData)
      let apiData = await getChannels();
      apiData.status === "S" ? setChannels(apiData.result_info) : setChannels([]);
    })();
  }, [navigate]);

  return (
    <div className="bootstrap-content">
      <h5 className='mb-4'>Create Channel</h5>
      <div className="ui-card">
        <div className="row">
          <div className="col-12 mb-2">
            <div className="form-group w-100">
              <select
                name="channel"
                className="w-100 form-select mx-auto"
                placeholder="Channel"
                required={true}
                value={channelInfo.channel_id}
                onChange={(ev) => {
                  let val = ev.target.value;
                  let selectedChannel = (val && channels && channels.length > 0) ? channels.filter((channel) => (channel.review_channel_id === +val)) : [{ channel_name: '' }];
                  console.log(selectedChannel)
                  setChannelInfo({ ...channelInfo, channel_id: ev.target.value, channel_name: selectedChannel[0].channel_name })
                }}
              >
                <option value="">-- Select Channel -- *</option>
                {channels &&
                  channels.map((channel) => (
                    <option
                      key={channel.review_channel_id}
                      value={channel.review_channel_id}
                    >
                      {channel.channel_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <input type='text' required={true} placeholder='Channel Url *' className="w-100" value={channelInfo.channel_url}
                onChange={(ev) => {
                  setChannelInfo({ ...channelInfo, channel_url: ev.target.value })
                }} label={'Channel Name'} />
            </div>
          </div>
          <div className="col-12">
            <div className="text-center mt-3">
              <button className='btn btn-dark px-4' onClick={() => {
                submit();
              }}>Create</button>
              <button className='btn btn-dark px-4 ms-3' onClick={() => {
                navigate('/grow/manage-account-channels')
              }}>Go Back</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateAccountChannel