import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';
import { forgotPassword } from '../../Api';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async () => {
        Swal.fire({
            html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
            customClass: {popup: 'loader'},
            showConfirmButton: false,
            heightAuto: false
        })
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        if(!email){
            Swal.fire({
                text: 'Email required!',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else if(!emalval.test(email)){
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else{
            let apiRes = await forgotPassword({email: email.toLowerCase()});
            if(apiRes.status === "S"){
              Swal.fire({
                text: 'An email is sent to your GoodReviews account. Redirecting to login',
                icon: 'success',
                showConfirmButton: false,
                heightAuto: false,
                timer: 2000
              }).then(() => {
                navigate('/empower')
              })
            }else if(apiRes.status === "E" && apiRes.result_code === 404){
                Swal.fire({
                    text: 'Invalid Email',
                    icon: 'warning',
                    iconColor: '#000',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 2000
                  })
            }else{
              Swal.close()
            }
        }
    };
    return (
        <div className='bootstrap-content login-page'>
            <div className="row g-0 w-100 h-100" >
                <div className="col-6 d-none d-lg-block">
                    <div className="login-left">
                        <div className="ellipse"></div>
                        <h6 className='login-heading'>Tell Your Reviews </h6>
                        <h6 className='login-subheading'>We Make It Global</h6>
                        <div className="bg-color">
                            <div className="image-container">
                                <img src={require('../../website/assets/video-file.png')} alt="" />
                            </div>
                        </div>
                        <h6 className='login-desc mt-3'>Trusted By 10,000+ Global Companies of all sizes</h6>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../website/assets/aws.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../website/assets/microsoft.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../website/assets/google-partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../website/assets/katalon.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../website/assets/partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../website/assets/ware.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../website/assets/citrix.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../website/assets/salesforce.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../website/assets/norton.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../website/assets/symantec.png')} alt="" className='logos' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 h-100">
                    <div className="login-right">
                        <div className="login-card">
                            <div className="text-center">
                                <img src={require('../../website/assets/logo.png')} alt="" className='logo-image' />
                                <h6 className='login-welcometext'>Forgot Password</h6>
                            </div>
                            <div className="text-start">
                                <p className='login-subtext'>Please enter your email Address</p>
                                <div className="mb-3 text-start">
                                    <label for="exampleInputEmail1" className="form-label">Email</label>
                                    <input type="text"
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="forgotAdminEmail"
                                        placeholder='Type your Work Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete='goodreviews'
                                    />
                                </div>
                                <button className='text-center btn btn-black' onClick={() => {handleSubmit()}}>
                                    Send
                                </button>
                            </div>
                            <div className='text-center'>
                                <h6 className='forgot-text'>Remember Your Password ?
                                    <Link to='/empower' className='text-decoration-none text-black'>
                                        <span className='ms-2'>Login</span>
                                    </Link>
                                </h6>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default ForgotPassword