import React from 'react'
import './setupgooglebusiness.scss'
import Header from '../../../components/header/Header'
import ButtonsContainer from '../../../components/buttonscontainer/ButtonsContainer'
import { useNavigate } from 'react-router-dom'
import Footer from '../../../components/footer/Footer'
import { Helmet } from 'react-helmet-async'

function SetupGoogleBusinessProfile() {
    const navigate = useNavigate();

    return (<>
        <Helmet>
            <title>Setup Google Business Profile | America's #1 Choice for Reputation Excellence | Boost Your Online Presence with Good Reviews</title>
            <meta name="description" content="Supercharge your online visibility with Good Reviews' Google Business Profile setup service. Increase local search visibility, easily manage business information, and engage directly with customers. Start your free trial today and put your business on the map!" />
            <meta name="keywords" content="Google Business Profile, local SEO, online visibility, business listing management, customer engagement, Google Maps optimization, local search, business information updates, review management, Good Reviews" />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://goodreviews.co/capabilities/setup-google-business-profile" />
            <meta property="og:title" content="Supercharge Your Business with Google Business Profile Setup | Good Reviews" />
            <meta property="og:description" content="Boost local visibility, manage your business info, and engage customers directly. Let Good Reviews help you set up and optimize your Google Business Profile. Start your free trial now!" />
            <meta property="og:image" content="https://www.goodreviews.co/logo.png" />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://goodreviews.co/capabilities/setup-google-business-profile" />
            <meta property="twitter:title" content="Supercharge Your Business with Google Business Profile Setup | Good Reviews" />
            <meta property="twitter:description" content="Boost local visibility, manage your business info, and engage customers directly. Let Good Reviews help you set up and optimize your Google Business Profile. Start your free trial now!" />
            <meta property="twitter:image" content="https://www.goodreviews.co/logo.png" />
        </Helmet>
        <div className='bootstrap-content google-business'>
            <div className="header-sec">
                <div className="bg-white">
                    <Header />
                </div>
            </div>
            <section className='section-padding banner-bg'>
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-lg-7">
                            <h1 className='banner-heading text-white'>Setup Google <br /> Business Profile</h1>
                            <h3 className='mb-0 banner-sub-heading text-white'>Supercharge Your Online <br className='d-none d-lg-block' /> Presence with Good Reviews</h3>
                        </div>
                        <div className="col-12 col-lg-5 mb-lg-0 mb-3">
                            <div className="text-center">
                                <img src={require('../../../assets/capabilites/setup-google-business-banner-image.png')} alt="" className='main-image' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-padding'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-7">
                            <h3 className='list-heading'>Why Your Business Needs a Google <br className='d-none d-lg-block' /> Business Profile ?</h3>
                            <div className='list-el'>
                                <div>
                                    <img src={require("../../../assets/logo-fav.png")} className='list-left-image' alt="" />
                                </div>
                                <div>
                                    <h4 className='list-sub-heading'>Increased Visibility:</h4>
                                    <p className='mb-0 list-text'>Put your business on the map – literally! Your profile ensures you appear in local searches on Google Maps and relevant searches on Google.</p>
                                </div>
                            </div>
                            <div className='list-el'>
                                <div>
                                    <img src={require("../../../assets/logo-fav.png")} className='list-left-image' alt="" />
                                </div>
                                <div>
                                    <h4 className='list-sub-heading'>Share Essential Information</h4>
                                    <p className='mb-0 list-text'>Easily update your contact details, hours of operation, website link, and more, all in one convenient place.</p>
                                </div>
                            </div>
                            <div className='list-el'>
                                <div>
                                    <img src={require("../../../assets/logo-fav.png")} className='list-left-image' alt="" />
                                </div>
                                <div>
                                    <h4 className='list-sub-heading'>Engage with Customers</h4>
                                    <p className='mb-0 list-text'>Respond to reviews, share updates and promotions, and even message directly with customers through your profile.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="text-center">
                                <img src={require('../../../assets/capabilites/business-profile.png')} alt="" className='main-image side-image' />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-5">
                        <div className="col-12">
                            <div className="py-lg-4">
                                <ButtonsContainer navigate={navigate} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    </>)
}

export default SetupGoogleBusinessProfile
