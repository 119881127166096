import React, { useState } from 'react'
import "../../css/bootstrap.scss"
import "./contact.scss"
import { useNavigate } from 'react-router-dom'
import { login } from '../../../Api';
import Swal from 'sweetalert2';
import { useRole } from '../../../RoleContext';
import { MdStar } from 'react-icons/md';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

function ContactUs() {
    const { role, setRole } = useRole()
    const navigate = useNavigate();
    const [formInfo, setFormInfo] = useState({name: '', email: '', company_name: '', country_code: '', mobile: '', message: ''});

    const handleSubmit = async () => {
        Swal.fire({
            html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
            customClass: { popup: 'loader' },
            showConfirmButton: false,
            heightAuto: false
        })
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        if (!formInfo.email) {
            Swal.fire({
                text: 'Email required!',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!emalval.test(formInfo.email)) {
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let data = {
                email: formInfo.email.toLowerCase(),
            }
            Swal.fire({
                text: 'Your enquiry reached us. We will get in touch with you shorlty.',
                icon: 'success',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            }).then(() => {
                navigate('/')
            })
            console.log(formInfo)
            // let apiRes = await login(data);
            // console.log(apiRes)
            // if (apiRes.status === "S") {
            //     Swal.fire({
            //         text: 'Your enquiry reached us. We will get in touch with you shorlty.',
            //         icon: 'success',
            //         iconColor: '#000',
            //         showConfirmButton: false,
            //         heightAuto: false,
            //         timer: 1500
            //     }).then(() => {
            //         navigate('/')
            //     })
            // } else if (apiRes.status === 'E' && apiRes.result_code === 404) {
            //     Swal.fire({
            //         text: 'Invalid Credentials',
            //         icon: 'warning',
            //         iconColor: '#000',
            //         showConfirmButton: false,
            //         heightAuto: false,
            //         timer: 1500
            //     })
            // } else if (apiRes.status === 'E' && apiRes.result_code === 401) {
            //     Swal.fire({
            //         text: 'Invalid Credentials',
            //         icon: 'warning',
            //         iconColor: '#000',
            //         showConfirmButton: false,
            //         heightAuto: false,
            //         timer: 1500
            //     })
            // } else {
            //     // Swal.fire({
            //     //     text: JSON.stringify(apiRes.error),
            //     //     icon: 'warning',
            //     //     iconColor: '#000',
            //     //     showConfirmButton: false,
            //     //     heightAuto: false,
            //     //     timer: 1500
            //     // })
            //     Swal.fire({
            //         text: 'Server Error',
            //         icon: 'warning',
            //         iconColor: '#000',
            //         showConfirmButton: false,
            //         heightAuto: false,
            //         timer: 1500
            //     })
            // }
        }
    }
    const handleKeyDown = (ev) => {
        if (ev.key === "Enter") {
            ev.preventDefault();
            // handleSubmit();
        }
    };

    return (<>
        <div className='bootstrap-content contact-page' key={'role-' + role}>
            <div className="row g-0 w-100 h-100" >
                <div className="col-7 d-none d-lg-block">
                    <div className='login-left'>
                        <div className="ellipse"></div>
                        <div>
                            <img src={require('../../assets/logo.png')} alt="" className='logo-image pointer' onClick={() => { navigate('/') }}  />
                            <h2><strong>Get in Touch.</strong></h2>
                        </div>
                        <div className='d-flex mt-3'>
                            <div className='me-2'>
                                <div>{[1,2,3,4,5].map(() => <MdStar />)}</div>
                                <p className='mb-0'>4.8 out of 5 Rating on <img src={require('../../assets/google-contact.png')} alt="" /></p>
                            </div>
                            <div style={{borderRight: '1px solid #000'}}></div>
                            <div className='ms-2'>
                                <div>{[1,2,3,4,5].map(() => <MdStar />)}</div>
                                <p className='mb-0'>4.7 out of 5 Rating on <img src={require('../../assets/TripAdvisor-contact.png')} alt="" /></p>
                            </div>
                        </div>
                        <h6 className='login-desc mt-3'>Our cutting-edge software empowers you to harness the power of authentic customer feedback, driving unparalleled growth for your business. Gain invaluable insights, build credibility, and outshine the competition - all through a seamless, user-friendly platform designed to maximize your success.</h6>
                        <p className='login-copy mb-0'>&copy; 2024 Good Reviews.io - Privacy Policy
                        </p>
                    </div>
                </div>
                <div className="col-12 col-lg-5 h-100">
                    <div className="login-right">
                        <div className="login-card">
                            <div className="text-center">
                                <img src={require('../../assets/logo.png')} alt="" className='logo-image pointer d-lg-none' onClick={() => { navigate('/') }}  />
                                <h6 className='login-welcometext'>Contact Us</h6>
                            </div>
                            <div className="text-start">
                                <div className="mb-3 text-start">
                                    <input type="text"
                                        name="User_Name"
                                        className="form-control"
                                        aria-describedby="userName"
                                        placeholder='Full Name'
                                        value={formInfo.name}
                                        onChange={(e) => setFormInfo({...formInfo, name: e.target.value})}
                                        autoComplete='goodreviews'
                                    />
                                </div>
                                <div className="mb-3 text-start">
                                    <input type="text"
                                        name="User_Email"
                                        className="form-control"
                                        aria-describedby="userEmail"
                                        placeholder='Email'
                                        value={formInfo.email}
                                        onChange={(e) => setFormInfo({...formInfo, email: e.target.value})}
                                        autoComplete='goodreviews'
                                    />
                                </div>
                                <div className="mb-3 text-start">
                                    <input type="text"
                                        name="User_CompanyName"
                                        className="form-control"
                                        aria-describedby="userCompanyName"
                                        placeholder='Company Name'
                                        value={formInfo.company_name}
                                        onChange={(e) => setFormInfo({...formInfo, company_name: e.target.value})}
                                        autoComplete='goodreviews'
                                    />
                                </div>
                                <div className="mb-3 text-start">
                                    <PhoneInput className='phone form-control'
                                        placeholder="(201) 555-0123"
                                        value={formInfo.mobile}
                                        defaultCountry='US'
                                        onChange={(val) => {setFormInfo({...formInfo, mobile: val})}}/>
                                    {/* <div className="form-control">
                                        <select name="" id=""></select>
                                        <input type="text"
                                            name="User_CompanyName"
                                            className=""
                                            aria-describedby="userCompanyName"
                                            placeholder='(201) 555-0123'
                                            value={formInfo.company_name}
                                            onChange={(e) => setFormInfo({...formInfo, company_name: e.target.value})}
                                            autoComplete='goodreviews'
                                        />
                                    </div> */}
                                </div>
                                <div className="mb-3 text-start">
                                    <textarea rows={4} type="text"
                                        name="User_Message"
                                        className="form-control"
                                        aria-describedby="userMessage"
                                        placeholder='Write Message'
                                        value={formInfo.message}
                                        onChange={(e) => setFormInfo({...formInfo, message: e.target.value})}
                                        autoComplete='goodreviews'
                                    />
                                </div>
                                <button className='text-center btn btn-black' onClick={() => { handleSubmit() }}>
                                    SEND
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    </>)
}

export default ContactUs