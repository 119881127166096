import React, { useState } from 'react'
import "../../css/bootstrap.scss"
import "./createaccount.scss"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2';

function WebCreateAccount() {
    const location = useLocation();
    const [email, setEmail] = useState('');
    const plan = location.state?.plan || 'Free';
    const subscriptionType = location.state?.subscriptionType || 'Monthly';
    const navigate = useNavigate();

    const handleSubmit = () => {
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        if(!email){
            Swal.fire({
                text: 'Email required!',
                icon: 'warning',
                iconColor: '#000',
                heightAuto: false,
                timer: 3000,
                showConfirmButton: false
            })
        }else if(!emalval.test(email)){
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else{
            navigate('/account-details', { state: { email: email.toLowerCase(), plan, subscriptionType } });
        }
    };
    return (
        <div className='bootstrap-content login-page'>
            <div className="row g-0 w-100 h-100" >
                <div className="col-6 d-none d-lg-block">
                    <div className="login-left">
                        <div className="ellipse"></div>
                        <h6 className='login-heading'>Tell Your Reviews </h6>
                        <h6 className='login-subheading'>We Make It Global</h6>
                        <div className="bg-color">
                            <div className="image-container">
                                <img src={require('../../assets/video-file.png')} alt="" />
                            </div>
                        </div>
                        <h6 className='login-desc mt-3'>Trusted By 10,000+ Global Companies of all sizes</h6>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/aws.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/microsoft.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/google-partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/katalon.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/ware.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/citrix.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/salesforce.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/norton.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/symantec.png')} alt="" className='logos' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 h-100">
                    <div className="login-right">
                        <div className="login-card">
                            <div className="text-center">
                                <img src={require('../../assets/logo.png')} alt="" className='logo-image' />
                                <h6 className='login-welcometext'>Welcome to Good Reviews</h6>
                            </div>
                            <div className="text-start">
                                <p className='login-subtext'>Use your Work email for better experience </p>
                                <div className="mb-3 text-start">
                                    <label for="exampleInputEmail1" className="form-label">Email *</label>
                                    <input type="text"
                                        name="Create_Email"
                                        className="form-control"
                                        aria-describedby="createEmail"
                                        placeholder='Type your Work Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        autoComplete='goodreviews'
                                    />
                                </div>
                                {/* <Link to='/signin' className='text-decoration-none text-black'> */}
                                <button className='text-center btn btn-black' onClick={() => {handleSubmit()}}>
                                    Continue with your email
                                </button>

                                {/* </Link> */}
                            </div>
                            <div className='text-center'>
                                <h6 className='forgot-text'>Already have an account ?
                                    <Link to='/grow' className='text-decoration-none text-black'>
                                        <span className='ms-2'>Login</span>
                                    </Link>
                                </h6>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default WebCreateAccount
