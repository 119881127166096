import React from 'react'
import "../../css/bootstrap.scss"
import "./createaccount.scss"
import { useNavigate } from 'react-router-dom'

function Failed() {
    const navigate = useNavigate();

    return (
        <div className='bootstrap-content login-page'>
            <div className="row g-0 w-100 h-100" >
                <div className="col-6 d-none d-lg-block">
                    <div className="login-left">
                        <div className="ellipse"></div>
                        <h6 className='login-heading'>Tell Your Reviews </h6>
                        <h6 className='login-subheading'>We Make It Global</h6>
                        <div className="bg-color">
                            <div className="image-container">
                                <img src={require('../../assets/video-file.png')} alt="" />
                            </div>
                        </div>
                        <h6 className='login-desc mt-3'>Trusted By 10,000+ Global Companies of all sizes</h6>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/aws.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/microsoft.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/google-partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/katalon.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/ware.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/citrix.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/salesforce.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/norton.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/symantec.png')} alt="" className='logos' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 h-100">
                    <div className="login-right">
                        <div className="login-card">
                            <div className="text-center">
                                <img src={require('../../assets/logo.png')} alt="" className='logo-image' />
                                <h6 className='login-welcometext'>Payment Failed!</h6>
                            </div>
                            <div className="text-start">
                                <p className='login-subtext failed-text'>Your payment is failed and you are not being charged!</p>
                                <p className='login-subtext failed-text'>Please retry again by clicking on the below button</p>
                               
                                {/* <Link to='/signin' className='text-decoration-none text-black'> */}
                                <button className='text-center btn btn-black' onClick={() => {navigate('/pricing')}}>
                                    Try Again
                                </button>

                                {/* </Link> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default Failed
