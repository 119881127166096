import React, { useEffect, useRef, useState } from 'react'
// import "./create.scss"
import moment from 'moment';
import { compressImg } from '../../../../ImageCompressor';
import Swal from 'sweetalert2';
import { getAccountInfo, updateAccount } from '../../../../Api';
import { useNavigate, useParams } from 'react-router-dom';

const InputField = ({ disabled, value, valueFunc, label }) => {
  const inputRef = useRef(null);
  const floatRef = useRef(null);

  return (
    <>
      <span ref={floatRef} onClick={() => {
        if (!disabled && inputRef.current) {
          inputRef.current.focus();
        }
      }} className={value ? 'float' : ''}>{label}</span>
      <input disabled={disabled} ref={inputRef} type={"text"} className='w-100'
        onFocus={() => {
          if (floatRef.current) {
            floatRef.current.className = "float"
          }
        }}
        onBlur={() => {
          if (value) {
            if (floatRef.current) {
              floatRef.current.className = "float"
            }
          } else {
            if (floatRef.current) {
              floatRef.current.className = ""
            }
          }
        }}
        value={value}
        maxLength={label === 'Mobile' && 20}
        onChange={(ev) => { valueFunc(ev.target.value) }}
      />
    </>
  )
}

const SelectField = ({ value, valueFunc, label }) => {
  const inputRef = useRef(null);
  const floatRef = useRef(null);

  return (
    <>
      <span ref={floatRef} onClick={() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }} className={value ? 'float' : ''}>{label}</span>
      <select ref={inputRef} type={"text"} className='w-100'
        onFocus={() => {
          if (floatRef.current) {
            floatRef.current.className = "float"
          }
        }}
        onBlur={() => {
          if (value) {
            if (floatRef.current) {
              floatRef.current.className = "float"
            }
          } else {
            if (floatRef.current) {
              floatRef.current.className = ""
            }
          }
        }}
        value={value}
        onChange={(ev) => { valueFunc(ev.target.value) }}
      >
        <option className='selectOption' value=""></option>
        <option className='selectOption' value="Starter">Starter</option>
        <option className='selectOption' value="Professional">Professional</option>
        <option className='selectOption' value="Enterprise">Enterprise</option>
      </select>
    </>
  )
}

const SelectPlanType = ({ value, valueFunc, label }) => {
  const inputRef = useRef(null);
  const floatRef = useRef(null);

  return (
    <>
      <span ref={floatRef} onClick={() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }} className={value ? 'float' : ''}>{label}</span>
      <select ref={inputRef} type={"text"} className='w-100'
        onFocus={() => {
          if (floatRef.current) {
            floatRef.current.className = "float"
          }
        }}
        onBlur={() => {
          if (value) {
            if (floatRef.current) {
              floatRef.current.className = "float"
            }
          } else {
            if (floatRef.current) {
              floatRef.current.className = ""
            }
          }
        }}
        value={value}
        onChange={(ev) => { valueFunc(ev.target.value) }}
      >
        <option className='selectOption' value="Monthly">Monthly</option>
        <option className='selectOption' value="Yearly">Yearly</option>
      </select>
    </>
  )
}

function EditAccount() {
  const navigate = useNavigate();
  const { correl_id } = useParams();
  const [formInfo, setFormInfo] = useState({
    company_name: '',
    email: '',
    first_name: '',
    last_name: '',
    mobile: '',
    website: '',
    invites: '',
    validity_start_date: '',
    validity_end_date: '',
  })
  const [validityStartDate, setValidityStartDate] = useState('');
  const [image, setImage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const imageRef = useRef(null)
  const [plan, setPlan] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');

  const handleImage = (event) => {
    console.log(event);
    setImageFile(null);
    const file = event.target.files[0];
    let splitfile = file.name;
    let extension = splitfile.split('.').pop();
    if (extension === 'png' || extension === 'PNG' || extension === 'jpg' || extension === 'JPG' || extension === 'jpeg' || extension === 'JPEG') {
      console.log('valid file');
      var reader = new FileReader();
      if (event.target.files[0]) {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (e) => {
          setImage(e.target.result);
        }
        compressImg(event.target.files[0]).then(img => {
          setImageFile(img);
        });
      }
    } else {
      Swal.fire({
        text: 'Invalid file format. Only .png, .jpg files are allowed',
        icon: 'warning',
        iconColor: '#000',
        heightAuto: false
      })
      return
    }
  }
  
  const validateWebsite = (url) => {
    const regex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+\/?)(\S*)$/;
    return regex.test(url);
  };

  const isValidAmericanNumber = (number) => {
    const regex = /^(\+1\s?)?(\d{3}|\(\d{3}\))[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return regex.test(number);
  };

  const submit = async () => {
    Swal.fire({
      html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
      customClass: { popup: 'loader' },
      showConfirmButton: false,
      heightAuto: false
    })
    if (!formInfo.company_name) {
      Swal.fire({
        text: 'Company name required!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    }else if (!formInfo.first_name) {
      Swal.fire({
        text: 'First name required!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    }else if (!formInfo.last_name) {
      Swal.fire({
        text: 'Last name required!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    } else if (formInfo.mobile && !isValidAmericanNumber(formInfo.mobile)) {
      Swal.fire({
        text: 'Please enter valid Mobile Number',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    }else if (formInfo.website && !validateWebsite(formInfo.website)) {
      Swal.fire({
        text: 'Please enter valid Website',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    }
    else if (!plan) {
      Swal.fire({
        text: 'Plan required!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    } else if (!subscriptionType) {
      Swal.fire({
        text: 'Subscription type required!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    } else if (!formInfo.validity_start_date) {
      Swal.fire({
        text: 'Validity Start Date required!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    }else if(!(moment(formInfo.validity_start_date).format('MM-DD-YYYY') === formInfo.validity_start_date)){
      Swal.fire({
        text: 'Please enter valid start date',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    } else if (!formInfo.validity_end_date) {
      Swal.fire({
        text: 'Validity End Date required!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    }else if(!(moment(formInfo.validity_end_date).format('MM-DD-YYYY') === formInfo.validity_end_date)){
      Swal.fire({
        text: 'Please enter valid end date',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    }  else {
      let formData = new FormData();
      formData.append('company_name', formInfo.company_name);
      formData.append('email', formInfo.email);
      formData.append('first_name', formInfo.first_name);
      formData.append('last_name', formInfo.last_name);
      formData.append('mobile', formInfo.mobile);
      formData.append('website', formInfo.website);
      formData.append('plan', plan);
      formData.append('subscription_type', subscriptionType);
      formData.append('invites', formInfo.invites);
      formData.append('validity_start_date', formInfo.validity_start_date ? moment(formInfo.validity_start_date).format('YYYY-MM-DD') : '');
      formData.append('validity_end_date', formInfo.validity_end_date ? moment(formInfo.validity_end_date).format('YYYY-MM-DD') : '');
      if (imageFile) {
        formData.append('logo', imageFile)
      }
      let apiRes = await updateAccount(formData, correl_id);
      if (apiRes.status === "S") {
        Swal.fire({
          text: 'Account updated successfully',
          icon: 'success',
          showConfirmButton: false,
          heightAuto: false,
          timer: 2000
        }).then(() => {
          navigate('/empower/manage-accounts')
        })
      } else {
        Swal.close()
      }
    }
  }

  useEffect(() => {
    if (plan) {
      let inviteCount = '';
      if (plan === 'Starter') {
        inviteCount = subscriptionType === 'Yearly' ? (500*12) : 500;
        setFormInfo({ ...formInfo, invites: inviteCount })
      } else if (plan === 'Professional') {
        inviteCount = subscriptionType === 'Yearly' ? (1500*12) : 1500;
        setFormInfo({ ...formInfo, invites: inviteCount })
      }
    }
  }, [plan])

  useEffect(() => {
    if (subscriptionType) {
      let inviteCount = '';
      if (plan === 'Starter') {
        inviteCount = subscriptionType === 'Yearly' ? (500*12) : 500;
        setFormInfo({ ...formInfo, invites: inviteCount })
      } else if (plan === 'Professional') {
        inviteCount = subscriptionType === 'Yearly' ? (1500*12) : 1500;
        setFormInfo({ ...formInfo, invites: inviteCount })
      }
    }
  }, [subscriptionType])

  useEffect(() => {
    (async () => {
      let apiRes = await getAccountInfo(correl_id);
      if (apiRes.status === 'S') {
        setImage(apiRes.result_info.logo);
        setFormInfo({
          company_name: apiRes.result_info.company_name,
          mobile: (apiRes.result_info.mobile && apiRes.result_info.mobile !== 'null') ? apiRes.result_info.mobile : '',
          first_name: apiRes.result_info.first_name,
          last_name: apiRes.result_info.last_name,
          email: apiRes.result_info.email,
          website: (apiRes.result_info.website && apiRes.result_info.website !== 'null') ? apiRes.result_info.website : '',
          invites: (apiRes.result_info.invites && apiRes.result_info.invites !== 'null') ? apiRes.result_info.invites : '',
          validity_start_date: apiRes.result_info.validity_start_date ? moment(apiRes.result_info.validity_start_date).format('MM-DD-YYYY') : '',
          validity_end_date: apiRes.result_info.validity_end_date ? moment(apiRes.result_info.validity_end_date).format('MM-DD-YYYY') : '',
        });
        
        setPlan(apiRes.result_info.plan);
        setSubscriptionType(apiRes.result_info.subscription_type);
      }
    })()
  }, [correl_id])

  return (
    <div className="bootstrap-content custom-page">
      <h5 className='heading mb-4'>Edit Account</h5>
      <div className="ui-card">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="row g-4">
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={false} value={formInfo.company_name} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, company_name: val })
                  }} label={'Company Name *'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={true} value={formInfo.email} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, email: val })
                  }} label={'Email *'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={false} value={formInfo.first_name} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, first_name: val })
                  }} label={'First Name *'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={false} value={formInfo.last_name} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, last_name: val })
                  }} label={'Last Name *'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField
                    disabled={false}
                    value={formInfo.mobile}
                    valueFunc={(val) => {
                      setFormInfo({ ...formInfo, mobile: val });
                    }}

                    label={'Mobile'}
                  />
                </div>
                {formInfo.mobile && !isValidAmericanNumber(formInfo.mobile) && <p className='mb-0' style={{ color: 'red' }}>Invalid Mobile number</p>}
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={false} value={formInfo.website} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, website: val })
                  }} label={'Website'} />
                </div>
                {formInfo.website && !validateWebsite(formInfo.website) && <p className='mb-0' style={{ color: 'red' }}>Invalid Website</p>}
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <SelectField value={plan} valueFunc={setPlan} label={'Plan *'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <SelectPlanType value={subscriptionType} valueFunc={setSubscriptionType} label={'Subscritipon Type *'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={(plan !== 'Starter' && plan !== 'Professional') ? false : true} value={formInfo.invites} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, invites: val })
                  }} label={'Invites'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField value={formInfo.validity_start_date} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, validity_start_date: val })
                  }} label={'Validity start date (MM-DD-YYYY) *'} />
                </div>
                {formInfo.validity_start_date && !(moment(formInfo.validity_start_date).format('MM-DD-YYYY') === formInfo.validity_start_date) && <p className='mb-0' style={{ color: 'red' }}>Invalid Start Date</p>}
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField value={formInfo.validity_end_date} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, validity_end_date: val })
                  }} label={'Validity end date (MM-DD-YYYY) *'} />
                </div>
                {formInfo.validity_end_date && !(moment(formInfo.validity_end_date).format('MM-DD-YYYY') === formInfo.validity_end_date) && <p className='mb-0' style={{ color: 'red' }}>Invalid End Date</p>}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 text-center">
            {!imageFile && !image && <img src={"https://placehold.co/200x100"} className='logo-img' alt="" />}
            {!imageFile && image && <img src={'http://localhost:8000/files/account_logos/' + image} className='logo-img' alt="" />}
            {imageFile && <img src={image} className='logo-img' alt="" />}
            <input hidden ref={imageRef} type="file" onChange={handleImage} accept='.jpg, .png,. jpeg, .webp' />
            <div className='text-center mt-3'>
              <button className='btn btn-dark px-4' onClick={() => {
                if (imageRef.current) {
                  imageRef.current.click()
                }
              }}>Upload</button>
            </div>
          </div>
          <div className="col-12">
            <div className="text-center mt-3">
              <button className='btn btn-dark px-4' onClick={() => {
                submit();
              }}>Update</button>
              <button className='btn btn-dark px-4 ms-3' onClick={() => {
                navigate('/empower/manage-accounts')
              }}>Go Back</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditAccount