import React from 'react'
import "./invitesettings.scss"
import { FaQuestionCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'

function InviteSettings() {
    return (
        <div className='invite-settings-page'>
            <div className='invitationContainer'>
                <div className='headingContainer'>
                    <h5 className='heading mb-0'>Invite Settings</h5>
                    <FaQuestionCircle color='#E9C877' className='heading-icon' />
                </div>
                <p className='mb-0 invites-desc'>Change invitation settings</p>
                <div className="row mt-3">
                    <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                        <div className="invites-card">
                            <div className='invites-card-content'>
                                <h6 className='invites-card-heading mb-1'>From Business</h6>
                                <p className='invites-card-desc'>Cloud</p>
                            </div>
                            <div className='invites-card-content'>
                                <h6 className='invites-card-heading mb-1'>Sender Name</h6>
                                <p className='invites-card-desc'>Raj</p>
                            </div>
                            <div className='invites-card-content'>
                                <h6 className='invites-card-heading mb-1'>From Email</h6>
                                <p className='invites-card-desc'>raj@cloud.org</p>
                            </div>
                            <div className='invites-card-content'>
                                <h6 className='invites-card-heading mb-1'>Reply To</h6>
                                <p className='invites-card-desc'>raj@cloud.org</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                        <div className="invites-card">
                            <div className='invites-card-content'>
                                <h5 className='invites-card-heading mb-1'>Invitation Page</h5>
                                {/* <Link to='https://good-reviews.com/invite/impaxive-solutions'>https://good-reviews.com/invite/impaxive-solutions</Link> */}
                                <Link className='mb-0'>https://good-reviews.com/invite/impaxive-solutions</Link>
                                <p className='invites-dummy-text mb-0'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit quo deserunt unde exercitationem sint magni officia!</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default InviteSettings
