import React, { useEffect, useRef, useState } from 'react'
import "./recepients.scss"
import { FaQuestionCircle } from 'react-icons/fa'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { jsPDF } from 'jspdf';
import { getAccountData } from '../../../Api';
import moment from 'moment';

function Recepients() {
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [totalPages, setTotalPages] = useState(null);
    const [content, setContent] = React.useState([]);
    const tableRef = useRef(null)

    const filteredData = [...content].filter(row =>
        // Object.values(row).some(value =>
        //     value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        // )
        // ['first_name', 'last_name', 'email', 'channel_name'].some(key =>
        //     row[key]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        // )

        ['first_name', 'last_name', 'email', 'channel_name'].some(key =>
            row[key]?.toString().toLowerCase().includes(searchQuery.toLowerCase()))
    );

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setCurrentPage(1);
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // const handleExportPDF = () => {
    //     const doc = new jsPDF();
    //     const table = document.querySelector('.table');
    //     const rows = Array.from(table.querySelectorAll('tr'));

    //     rows.forEach((row, rowIndex) => {
    //         const cells = Array.from(row.querySelectorAll('th, td'));
    //         cells.forEach((cell, cellIndex) => {
    //             doc.text(cell.innerText, 10 + cellIndex * 50, 10 + rowIndex * 10);
    //         });
    //     });

    //     doc.save('table-data.pdf');
    // };

    const handleExportPDF = () => {
        // Initialize jsPDF
        const doc = new jsPDF();
    
        // Get the table without removing columns
        // const table = tableRef.current;
        // const headerRow = table.querySelector('thead tr');
        // const bodyRows = table.querySelectorAll('tbody tr');
    
        // Clone the table and remove action buttons
        const tableClone = tableRef.current.cloneNode(true);
        const headerRow = tableClone.querySelector('thead tr');
        headerRow.removeChild(headerRow.lastElementChild);
    
        const bodyRows = tableClone.querySelectorAll('tbody tr');
        bodyRows.forEach(row => {
          row.removeChild(row.lastElementChild);
        });
    
        // Extract table data
        const header = [...headerRow.querySelectorAll('th')].map(th => th.textContent);
        const data = [...bodyRows].map(row =>
          [...row.querySelectorAll('td')].map(td => td.textContent)
        );
    
        // Extract table data, skipping the 2nd, 3rd, and 4th rows
        // const header = [...headerRow.querySelectorAll('th')].map(th => th.textContent);
        // const data = [...bodyRows].filter((_, index) => index !== 1 && index !== 2 && index !== 3).map(row => 
        //     [...row.querySelectorAll('td')].map(td => td.textContent)
        // );
    
        // Set up PDF document
        // This sets the font size, calculates the width of the page and margins, 
        // and determines the width of each table cell based on the number of columns. 
        // It also sets the initial vertical position (yPosition) for drawing the table.
        doc.setFontSize(8);
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        const margin = 5;
        const usableWidth = pageWidth - 2 * margin;
        const cellWidth = usableWidth / header.length;
        const defaultCellHeight = 10; // Default cell height
        let yPosition = 20;
    
        // Calculate the maximum header height based on text wrapping
        let maxHeaderHeight = defaultCellHeight;
        header.forEach((text) => {
          const textLines = doc.splitTextToSize(text, cellWidth - 4);
          const cellHeight = textLines.length * 5 + 5; // 5 is approx line height
          if (cellHeight > maxHeaderHeight) maxHeaderHeight = cellHeight;
        });
    
        // Draw top border of the table
        doc.setLineWidth(0.3);
        doc.line(margin, yPosition, margin + usableWidth, yPosition);
    
        // Draw header
        doc.setTextColor(0);
        doc.setFont(undefined, 'bold');
        header.forEach((text, i) => {
          const textLines = doc.splitTextToSize(text, cellWidth - 4);
          doc.text(textLines, margin + i * cellWidth + 2, yPosition + 5, { maxWidth: cellWidth - 4 });
        });
        yPosition += maxHeaderHeight;
    
        // Draw horizontal line after header
        doc.line(margin, yPosition, margin + usableWidth, yPosition);
    
        // Prepare for drawing data rows
        doc.setFont(undefined, 'normal');
    
        // Function to draw vertical lines for the current page
        function drawVerticalLines(currentY) {
          for (let i = 0; i <= header.length; i++) {
            doc.line(margin + i * cellWidth, 20, margin + i * cellWidth, currentY);
          }
        }
    
        // Initial vertical lines (after header)
        drawVerticalLines(yPosition);
    
        // Draw data rows
        data.forEach((row) => {
          let rowHeight = defaultCellHeight;
    
          // Calculate row height based on content
          row.forEach((text) => {
            const textLines = doc.splitTextToSize(text, cellWidth - 4);
            const calculatedHeight = textLines.length * 5 + 5; // 5 is approx line height
            if (calculatedHeight > rowHeight) rowHeight = calculatedHeight;
          });
    
          // Check if adding this row exceeds the page height
          if (yPosition + rowHeight > pageHeight - 20) {
            // Draw bottom horizontal line for the current page
            doc.line(margin, yPosition, margin + usableWidth, yPosition);
            
            // Draw vertical lines for the current page
            drawVerticalLines(yPosition);
    
            // Add a new page
            doc.addPage();
            
            // Reset yPosition for the new page
            yPosition = 20;
    
            // Draw top border of the new table
            doc.setLineWidth(0.3);
            doc.line(margin, yPosition, margin + usableWidth, yPosition);
    
            // Draw vertical lines starting after header
            drawVerticalLines(yPosition);
          }
    
          // Draw cell content
          row.forEach((text, i) => {
            const textLines = doc.splitTextToSize(text, cellWidth - 4);
            doc.text(textLines, margin + i * cellWidth + 2, yPosition + 5, { maxWidth: cellWidth - 4 });
          });
    
          // Draw horizontal line after row
          doc.line(margin, yPosition, margin + usableWidth, yPosition);
          yPosition += rowHeight;
    
          // Draw vertical lines up to the current yPosition
          drawVerticalLines(yPosition);
        });
    
        // Draw bottom line
        doc.line(margin, yPosition, margin + usableWidth, yPosition);
    
        // Draw final vertical lines
        drawVerticalLines(yPosition);
    
        let newDate = moment(new Date()).format('MM-DD-YYYY');
        let userData = JSON.parse(localStorage.getItem('userData') || '{}')
        // Save the PDF
        doc.save(userData.first_name+'_Recipients_goodreviews _' + newDate + '.pdf');
    };

    const handleExportCSV = () => {
        const table = document.querySelector('.table');
        const rows = Array.from(table.querySelectorAll('tr'));
        let csvContent = '';

        rows.forEach(row => {
            const cells = Array.from(row.querySelectorAll('th, td'));
            cells.forEach((cell, index) => {
                // cells.slice(0, -1).forEach((cell, index) => {
                csvContent += index === 0 ? `${cell.innerText}` : `,${cell.innerText}`;
            });
            csvContent += '\n';
        });

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        let newDate = moment(new Date()).format('MM-DD-YYYY');
        let userData = JSON.parse(localStorage.getItem('userData') || '{}')
        link.download = userData.first_name+'_Recipients_goodreviews _' + newDate + '.csv';
        link.click();
    };

    useEffect(() => {
        (async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let res = await getAccountData(userData.correl_id);
            if (res.status === 'S') {
                setContent(res.result_info)
                setTotalPages(Math.ceil(res.result_info.length / pageSize));
            }
        })()
    }, [])

    return (
        <div className='recepients-page'>
            <div className='recepients-container'>
                <div className='headingContainer'>
                    <h5 className='heading mb-0'>Recipients</h5>
                    <FaQuestionCircle color='#E9C877' className='heading-icon' />
                </div>
                <p className='mb-0 recepients-desc'>List of recipients invited</p>
                <div className='justify-content-between d-flex mt-4'>
                    <div className='d-flex align-items-center'>
                        <button className='btn btn-white me-3' onClick={handleExportPDF}>
                            PDF
                        </button>
                        <button className='btn btn-white' onClick={handleExportCSV}>
                            EXCEL
                        </button>
                    </div>
                    <input type="text" placeholder="Search" className='searchInput' value={searchQuery} onChange={handleSearchChange} />
                </div>
                <div className="recepients-card ">
                    <div className="table-container">
                        <table className="table" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th scope='col'>First Name</th>
                                    <th scope='col'>Last Name</th>
                                    <th scope='col'>Email</th>
                                    <th scope='col'>Channel</th>
                                    <th scope='col'>Sent Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData && (paginatedData.length > 0) && paginatedData.map((row, index) => (
                                    <tr key={'recepient-' + index}>
                                        <td>{row.first_name}</td>
                                        <td>{row.last_name}</td>
                                        <td>{row.email}</td>
                                        <td>{row.channel_name}</td>
                                        <td>{row.sent_date}</td>
                                    </tr>
                                ))}
                                {(!paginatedData || (paginatedData.length === 0)) &&
                                    <tr>
                                        <td>No recepient found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                }
                            </tbody>
                        </table>

                    </div>
                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
                            <MdKeyboardArrowLeft />
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
                            <MdKeyboardArrowRight />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Recepients
