import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./manage.scss";
import Swal from "sweetalert2";
import { changeAccountStatus, getAccounts } from "../../../../Api";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import jsPDF from "jspdf";
import moment from "moment";

function ManageAccounts() {
  const navigate = useNavigate();
  // const labelRef = useRef(null)
  // const inputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;
  const [totalPages, setTotalPages] = useState(null);
  const [content, setContent] = React.useState([]);
  const tableRef = useRef(null)

  const filteredData = [...content].filter((row) =>
    Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset current page when the search query changes
  };

  const paginatedData = [...filteredData].slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleExportPDF = () => {
    // Initialize jsPDF
    const doc = new jsPDF();

    // Get the table without removing columns
    // const table = tableRef.current;
    // const headerRow = table.querySelector('thead tr');
    // const bodyRows = table.querySelectorAll('tbody tr');

    // Clone the table and remove action buttons
    const tableClone = tableRef.current.cloneNode(true);
    const headerRow = tableClone.querySelector('thead tr');
    headerRow.removeChild(headerRow.lastElementChild);

    const bodyRows = tableClone.querySelectorAll('tbody tr');
    bodyRows.forEach(row => {
      row.removeChild(row.lastElementChild);
    });

    // Extract table data
    const header = [...headerRow.querySelectorAll('th')].map(th => th.textContent);
    const data = [...bodyRows].map(row =>
      [...row.querySelectorAll('td')].map(td => td.textContent)
    );

    // Extract table data, skipping the 2nd, 3rd, and 4th rows
    // const header = [...headerRow.querySelectorAll('th')].map(th => th.textContent);
    // const data = [...bodyRows].filter((_, index) => index !== 1 && index !== 2 && index !== 3).map(row => 
    //     [...row.querySelectorAll('td')].map(td => td.textContent)
    // );

    // Set up PDF document
    // This sets the font size, calculates the width of the page and margins, 
    // and determines the width of each table cell based on the number of columns. 
    // It also sets the initial vertical position (yPosition) for drawing the table.
    doc.setFontSize(8);
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 5;
    const usableWidth = pageWidth - 2 * margin;
    const cellWidth = usableWidth / header.length;
    const defaultCellHeight = 10; // Default cell height
    let yPosition = 20;

    // Calculate the maximum header height based on text wrapping
    let maxHeaderHeight = defaultCellHeight;
    header.forEach((text) => {
      const textLines = doc.splitTextToSize(text, cellWidth - 4);
      const cellHeight = textLines.length * 5 + 5; // 5 is approx line height
      if (cellHeight > maxHeaderHeight) maxHeaderHeight = cellHeight;
    });

    // Draw top border of the table
    doc.setLineWidth(0.3);
    doc.line(margin, yPosition, margin + usableWidth, yPosition);

    // Draw header
    doc.setTextColor(0);
    doc.setFont(undefined, 'bold');
    header.forEach((text, i) => {
      const textLines = doc.splitTextToSize(text, cellWidth - 4);
      doc.text(textLines, margin + i * cellWidth + 2, yPosition + 5, { maxWidth: cellWidth - 4 });
    });
    yPosition += maxHeaderHeight;

    // Draw horizontal line after header
    doc.line(margin, yPosition, margin + usableWidth, yPosition);

    // Prepare for drawing data rows
    doc.setFont(undefined, 'normal');

    // Function to draw vertical lines for the current page
    function drawVerticalLines(currentY) {
      for (let i = 0; i <= header.length; i++) {
        doc.line(margin + i * cellWidth, 20, margin + i * cellWidth, currentY);
      }
    }

    // Initial vertical lines (after header)
    drawVerticalLines(yPosition);

    // Draw data rows
    data.forEach((row) => {
      let rowHeight = defaultCellHeight;

      // Calculate row height based on content
      row.forEach((text) => {
        const textLines = doc.splitTextToSize(text, cellWidth - 4);
        const calculatedHeight = textLines.length * 5 + 5; // 5 is approx line height
        if (calculatedHeight > rowHeight) rowHeight = calculatedHeight;
      });

      // Check if adding this row exceeds the page height
      if (yPosition + rowHeight > pageHeight - 20) {
        // Draw bottom horizontal line for the current page
        doc.line(margin, yPosition, margin + usableWidth, yPosition);
        
        // Draw vertical lines for the current page
        drawVerticalLines(yPosition);

        // Add a new page
        doc.addPage();
        
        // Reset yPosition for the new page
        yPosition = 20;

        // Draw top border of the new table
        doc.setLineWidth(0.3);
        doc.line(margin, yPosition, margin + usableWidth, yPosition);

        // Draw vertical lines starting after header
        drawVerticalLines(yPosition);
      }

      // Draw cell content
      row.forEach((text, i) => {
        const textLines = doc.splitTextToSize(text, cellWidth - 4);
        doc.text(textLines, margin + i * cellWidth + 2, yPosition + 5, { maxWidth: cellWidth - 4 });
      });

      // Draw horizontal line after row
      doc.line(margin, yPosition, margin + usableWidth, yPosition);
      yPosition += rowHeight;

      // Draw vertical lines up to the current yPosition
      drawVerticalLines(yPosition);
    });

    // Draw bottom line
    doc.line(margin, yPosition, margin + usableWidth, yPosition);

    // Draw final vertical lines
    drawVerticalLines(yPosition);

    let newDate = moment(new Date()).format('MM-DD-YYYY');
    // Save the PDF
    doc.save('goodreviews_accounts_info_' + newDate + '.pdf');

  };

  const handleExportCSV = () => {
    const table = document.querySelector('.table');
    const rows = Array.from(table.querySelectorAll('tr'));
    let csvContent = '';

    rows.forEach(row => {
      const cells = Array.from(row.querySelectorAll('th, td'));
      cells.slice(0, -1).forEach((cell, index) => {
        csvContent += index === 0 ? `${cell.innerText}` : `,${cell.innerText}`;
      });
      csvContent += '\n';
    });

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    let newDate = moment(new Date()).format('MM-DD-YYYY')
    link.download = 'goodreviews_accounts_info_'+newDate+'.csv';
    link.click();
  };

  const edit = (correl_id) => {
    navigate("/empower/edit-account/" + correl_id);
  };
  const changeAccStatus = (correl_id, is_active) => {
    Swal.fire({
      text: `Are you sure you want to ${is_active === 'Y' ? 'Deactivate' : 'Activate'} the Account?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
    }).then(async (res) => {
      if (res.isConfirmed) {
        let apiRes = await changeAccountStatus(correl_id);
        console.log(apiRes);
        if (apiRes.status === "S") {
          Swal.fire({
            text: `Account status updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            fetchData();
          });
        }
      }
    });
  };

  const fetchData = async () => {
    let apiData = await getAccounts();
    if (apiData.status === "S") {
      apiData.result_info = apiData.result_info.filter((result) => (result.account_code !== "prod-owner"))
      setContent(apiData.result_info);
      setTotalPages(Math.ceil([...apiData.result_info].length / pageSize));
    } else {
      setContent([]);
      setTotalPages(0);
    };
  };

  useEffect(() => {
    (async () => {
      let apiData = await getAccounts();
      if (apiData.status === "S") {
        apiData.result_info = apiData.result_info.filter((result) => (result.account_code !== "prod-owner"))
        setContent(apiData.result_info);
        setTotalPages(Math.ceil([...apiData.result_info].length / pageSize));
      } else {
        setContent([]);
        setTotalPages(0);
      };
    })();
  }, [navigate]);

  const isValidMobileNumber = (mobile) => {
    return /^\d+$/.test(mobile); // Regular expression to check if the string contains only digits
  };

  return (
    // <div className="bootstrap-content manageContainer">
    //   <h5 className="page-heading">Manage Accounts</h5>
    //   <div className="d-flex align-items-center justify-content-between my-4">
    //     <div className="">
    //       <button className="btn btn-export px-4 me-3" onClick={handleExportPDF}>PDF</button>
    //       <button className="btn btn-export px-4" onClick={handleExportCSV}>EXCEL</button>
    //     </div>
    //     <div className="search-field">
    //       <span ref={labelRef} onClick={() => {
    //         if(inputRef.current){
    //           inputRef.current.focus();
    //         }
    //       }} className={searchQuery ? 'float': ''}>Search</span>
    //       <input ref={inputRef} type={"text"} className='w-100'
    //         onFocus={() => {
    //           if(labelRef.current){
    //             labelRef.current.className = "float"
    //           }
    //         }}
    //         onBlur={() => {
    //           if(searchQuery){
    //             if(labelRef.current){
    //               labelRef.current.className = "float"
    //             }
    //           }else{
    //             if(labelRef.current){
    //               labelRef.current.className = ""
    //             }
    //           }
    //         }} 
    //         value={searchQuery}
    //         onChange={handleSearchChange}
    //       />
    //     </div>
    //   </div>
    //   <div className="table-card p-0">
    //     <div className="tableContainer common-table">
    //       <table className="table" ref={tableRef}>
    //         <thead>
    //           <tr>
    //             <th>Company Name</th>
    //             <th>Mobile</th>
    //             <th>Email</th>
    //             <th>Invites</th>
    //             <th className="col-width">Actions</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {paginatedData &&
    //             paginatedData.length > 0 &&
    //             paginatedData.map((row, index) => (
    //               <tr key={index}>
    //                 <td>{row.company_name}</td>
    //                 <td>{row.mobile}</td>
    //                 <td>{row.email}</td>
    //                 <td>{row.invites}</td>
    //                 <td className="col-width">
    //                   <button
    //                     className="btn btn-dark px-4 me-2"
    //                     onClick={() => {
    //                       edit(row.correl_id);
    //                     }}
    //                   >
    //                     Edit
    //                   </button>
    //                   <button
    //                     className={`btn px-3 ${row.is_active === 'Y' ? 'btn-danger' : 'btn-dark'}`}
    //                     onClick={() => {
    //                       changeAccStatus(row.correl_id, row.is_active);
    //                     }}
    //                   >
    //                     {row.is_active === 'Y' ? 'Deactivate' : 'Activate'}
    //                   </button>
    //                 </td>
    //               </tr>
    //             ))}
    //         </tbody>
    //       </table>
    //       <div className="d-flex justify-content-end align-items-center table-footer">
    //         <button
    //           className="btn btn-light me-2"
    //           onClick={previousPage}
    //           disabled={currentPage === 1}
    //         >
    //           <MdChevronLeft size={20} />
    //         </button>
    //         <span>
    //           Page {currentPage} of {totalPages}
    //         </span>
    //         <button
    //           className="btn btn-light ms-2"
    //           onClick={nextPage}
    //           disabled={currentPage === totalPages}
    //         >
    //           <MdChevronRight size={20} />
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className='bootstrap-content manageContainer masterdata-page'>
      <div className='masterdata-container'>
        <div className='headingContainer'>
          <h5 className='heading mb-0'>Manage Accounts</h5>
        </div>
        <div className='justify-content-between d-flex mt-3'>
          <div className='d-flex align-items-center'>
            <button className='btn btn-white me-3' onClick={handleExportPDF}>
              PDF
            </button>
            <button className='btn btn-white' onClick={handleExportCSV}>
              EXCEL
            </button>
          </div>
          <input type="text" placeholder="Search" className='searchInput' value={searchQuery} onChange={handleSearchChange} />
        </div>
        <div className="masterdata-card ">
          <div className="table-container">
            <table className="table min-w-1200 large-table" ref={tableRef}>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Mobile</th>
                  <th>Email</th>
                  <th>Plan</th>
                  <th>Subscription Type</th>
                  <th>Invites</th>
                  <th>Validity End Date</th>
                  <th className="col-width">Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData && (paginatedData.length > 0) && paginatedData.map((row, index) => (
                  <tr key={'account-' + index}>
                    <td>{row.company_name}</td>
                    <td>{(row.mobile && row.mobile !== 'null') ? row.mobile : ''}</td>
                    <td>{row.email}</td>
                    <td>{row.plan}</td>
                    <td>{row.subscription_type}</td>
                    <td>{(row.invites && row.invites !== 'null') ? row.invites : ''}</td>
                    <td>{row.validity_end_date ? moment(row.validity_end_date).format('MM-DD-YYYY') : ''}</td>
                    <td className="col-width">
                      <button
                        className="btn btn-dark px-4 me-2"
                        onClick={() => {
                          edit(row.correl_id);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className={`btn px-3 ${row.is_active === 'Y' ? 'btn-danger' : 'btn-dark'}`}
                        onClick={() => {
                          changeAccStatus(row.correl_id, row.is_active);
                        }}
                      >
                        {row.is_active === 'Y' ? 'Deactivate' : 'Activate'}
                      </button>
                    </td>
                  </tr>
                ))}
                {(!paginatedData || (paginatedData.length === 0)) &&
                  <tr>
                    <td>No record found</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                }
              </tbody>
            </table>
            {/* <table className="table export-table min-w-1200 large-table d-none">
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Mobile</th>
                  <th>Email</th>
                  <th>Plan</th>
                  <th>Subscription Type</th>
                  <th>Invites</th>
                  <th>Validity End Date</th>
                  <th className="col-width">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredData && (filteredData.length > 0) && filteredData.map((row, index) => (
                  <tr key={'account-' + index}>
                    <td>{row.company_name}</td>
                    <td>{(row.mobile && row.mobile !== 'null') ? row.mobile : ''}</td>
                    <td>{row.email}</td>
                    <td>{row.plan}</td>
                    <td>{row.subscription_type}</td>
                    <td>{(row.invites && row.invites !== 'null') ? row.invites : ''}</td>
                    <td>{row.validity_end_date ? moment(row.validity_end_date).format('MM-DD-YYYY') : ''}</td>
                    <td className="col-width">
                      <button
                        className="btn btn-dark px-4 me-2"
                        onClick={() => {
                          edit(row.correl_id);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className={`btn px-3 ${row.is_active === 'Y' ? 'btn-danger' : 'btn-dark'}`}
                        onClick={() => {
                          changeAccStatus(row.correl_id, row.is_active);
                        }}
                      >
                        {row.is_active === 'Y' ? 'Deactivate' : 'Activate'}
                      </button>
                    </td>
                  </tr>
                ))}
                {(!paginatedData || (paginatedData.length === 0)) &&
                  <tr>
                    <td>No record found</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                }
              </tbody>
            </table> */}
          </div>
          <div className="d-flex justify-content-end align-items-center mt-3">
            <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
              <MdKeyboardArrowLeft />
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
              <MdKeyboardArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageAccounts;
