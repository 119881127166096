export const compressImg = async (file) => {
  const imageType = file.type;
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    // This event is triggered each time the reading operation is successfully completed.
    reader.onload = (ev) => {
      // Create an html image element
      const img = createImage(ev);
      // Fires immediately after the browser loads the object
      img.onload = () => {
        const elem = document.createElement('canvas');
        // resize width, height
        const ratio = Math.min(256 / img.width, 256 / img.height);
        elem.width = img.width * ratio;
        elem.height = img.height * ratio;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, elem.width, elem.height);
        ctx.canvas.toBlob(
          // callback, called when blob created
          (blob) => {
            const compressedFile = new File([blob], file.name, {
              type: imageType,
              lastModified: Date.now(),
            })
            resolve(compressedFile);
          },
          imageType
        );
      };
    };

    reader.onerror = (error) => reject(error);
  });
}

const createImage = (ev) => {
  let imageContent = ev.target.result;
  const img = new Image();
  img.src = imageContent;
  return img;
}