import React from 'react'
import "../../css/bootstrap.scss"
import "./privacy-policy.scss"
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import { Link } from 'react-router-dom'


function PrivacyPolicy() {

    return (<>
        <div className={`bootstrap-content privacy-policy-page`}>
            <div className="header-sec">
                <div className="bg-white">
                    <Header />
                </div>
            </div>
            <section className='section-padding'>
                <div className="container">
                    <div className="row justify-content-center mt-3 mt-lg-3">
                        <div className="col-12 col-lg-10 col-md-11 justify-content-start">
                            <h1 className='single-page-heading'>Privacy Policy</h1>
                            <div className="divider">
                              <div className="left"></div>
                              <div className="right"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-11 col-lg-7 col-md-9">
                      <div className="single-page-content">
                            <p>Effective Date: October 9th 2024</p>
                            <p>1. Introduction</p>
                            <p>At GoodReviews.co, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website and use our services.</p>
                            <p>2. Information We Collect</p>
                            <p>We may collect the following types of information:</p>
                            <ul>
                                <li>Personal Information: This includes your name, email address, phone number, and any other information you provide when registering for an account or contacting us.</li>
                                <li>Usage Data: We collect information on how you interact with our website, including your IP address, browser type, pages visited, and time spent on our site.</li>
                                <li>Cookies and Tracking Technologies: We use cookies and similar technologies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.</li>
                            </ul>
                            <p>3. How We Use Your Information</p>  
                            <p>We use the information we collect for various purposes, including:</p>
                            <p>To provide, maintain, and improve our services.</p>
                            <p>To communicate with you, including sending updates and promotional materials.</p>
                            <p>To monitor usage and analyze trends to improve user experience.</p>
                            <p>4. Disclosure of Your Information</p>
                            <p>We may share your information in the following circumstances:</p>
                            <ul>
                                <li>With Service Providers: We may share your information with third-party vendors who assist us in providing our services.</li>
                                <li>Legal Requirements: We may disclose your information if required by law or in response to valid requests by public authorities.</li>
                            </ul>
                            <p>5. Data Security</p>
                            <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure.</p>
                            <p>6. Your Rights</p>
                            <p>You have the right to:</p>
                            <ul>
                                <li>Access your personal information.</li>
                                <li>Request correction of inaccurate data.</li>
                                <li>Request deletion of your personal information.</li>
                                <li>Withdraw consent to data processing.</li>
                            </ul>
                            <p>7. Third-Party Links</p>
                            <p>Our website may contain links to third-party sites. We are not responsible for the privacy practices of these sites. We encourage you to read their privacy policies.</p>
                            <p>8. Changes to This Privacy Policy</p>
                            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
                            <p>9. Contact Us</p>
                            <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                            <p>Email: <Link to="mailto:support@goodreviews.co" target='_blank'>support@goodreviews.co</Link></p>
                                
                                
                                
                                
                                
                                
                                
                                
                                
                                
                                
                            </div>
                      </div>
                    </div>
                </div>

            </section>
          <Footer />
        </div>
    </>)
}

export default PrivacyPolicy
