import React from 'react'
import "./business.scss"
import { FaQuestionCircle } from 'react-icons/fa'
import { MdAddCircle } from 'react-icons/md'
import { AiOutlineClose } from 'react-icons/ai'

function BusinessSettings() {
    return (
        <div className='businessContainer'>
            <div className='businesscontent'>
                <h5 className='businessHeading mb-0'>Business Settings</h5>
                <FaQuestionCircle color='#E9C877' className='business-icon' />
            </div>
            <p className='mb-0 business-desc'>Change settings for Company Name here</p>
            <div className="row mt-3">
                <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                    <div className="business-card h-100">
                        <div className='card-row'>
                            <h6 className='business mb-3'>Business Name</h6>
                        </div>
                        {/* <div className='card-row'>
                            <h6 className='business mb-3'>Short URL</h6>
                        </div> */}
                        <div className='card-row'>
                            <h6 className='business mb-1'>Feedback Page URL</h6>
                            <p className='colorText'>https://goodreviews.com/</p>
                        </div>
                        {/* <div className='card-row'>
                            <h6 className='business mb-1'>Preferred Timezone</h6>
                            <select class="form-select" aria-label="Default select example">
                                <option value="(GMT-5.00) Eastern Time (US & Canada)">(GMT-5.00) Eastern Time (US & Canada)</option>
                                <option value="lorem">LoremIpsum</option>
                                <option value="lorem">LoremIpsum</option>
                            </select>
                        </div> */}
                        <div className="card-row mt-2">
                            <div className='businesscontent mb-0'>
                                <h6 className='business mb-0'>Notifications</h6>
                                <MdAddCircle color='#E9C877' />
                            </div>
                            <p className='descText mb-0'>raven@gmail.com <AiOutlineClose fontSize={'15px'} color='#E9C877' /></p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                    <div className="business-card justify-content-center text-center h-100">
                        <p className='preview text-center'>Preview only <span className='visit mt-5'>Visit Live Feedback Page</span></p>
                        <img src={require('../../assets/tree-image.jpg')} alt="" className='treeImage' />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default BusinessSettings
