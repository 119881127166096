// const baseUrl = "http://localhost:8080/api";
const baseUrl = "https://goodreviews.co/api";

export const login = async (obj) => {
  let res = await fetch(baseUrl+'/login', {
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Methods": "GET,PUT,POST,DELETE,PATCH",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}

export const changePassword = async (obj) => {
  let res = await fetch(baseUrl+'/changePassword', {
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Methods": "GET,PUT,POST,DELETE,PATCH",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}

export const forgotPassword = async (obj) => {
  let res = await fetch(baseUrl+'/forgotPassword', {
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Methods": "GET,PUT,POST,DELETE,PATCH",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}

export const userSubscription = async (obj) => {
  let res = await fetch(baseUrl+'/userSubscription', {
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Methods": "GET,PUT,POST,DELETE,PATCH",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const registerAccount = async (obj) => {
  let res = await fetch(baseUrl+'/registerAccount', {
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Methods": "GET,PUT,POST,DELETE,PATCH",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const verifyOtp = async (obj) => {
  let res = await fetch(baseUrl+'/verifyOtp', {
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Methods": "GET,PUT,POST,DELETE,PATCH",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const createAccountApi = async (obj) => {
  let res = await fetch(baseUrl+'/createAccount', {
    method: 'POST',
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Methods": "GET,PUT,POST,DELETE,PATCH"
    },
    body: obj
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getAccounts = async () => {
  let res = await fetch(baseUrl+'/getAllAccounts');
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getAccountInfo = async (correl_id) => {
  let res = await fetch(baseUrl+'/getAccountInfo/'+correl_id);
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const updateAccount = async (obj, correl_id) => {
  let res = await fetch(baseUrl+'/updateAccount/'+correl_id, {
    method: 'PUT',
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Methods": "GET,PUT,POST,DELETE,PATCH"
    },
    body: obj
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const changeAccountStatus = async (correl_id) => {
  let res = await fetch(baseUrl+'/changeAccountStatus/'+correl_id);
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}

// Channels
export const createChannelApi = async (obj) => {
  let res = await fetch(baseUrl+"/createChannel", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getChannelInfo = async (channel_id) => {
  let res = await fetch(baseUrl+"/getChannelInfo/"+channel_id);
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getChannels = async () => {
  let res = await fetch(baseUrl+"/getAllChannels");
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const updateChannel = async (obj, channel_id) => {
  let res = await fetch(`${baseUrl}/updateChannel/${channel_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const deleteChannel = async (channel_id) => {
  let res = await fetch(baseUrl+"/deleteChannel/"+channel_id, {
    method: "DELETE"
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}

// Account Channels
export const createAccountChannelApi = async (obj) => {
  let res = await fetch(baseUrl+"/createAccountChannel", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getAccountChannelInfo = async (account_channel_id) => {
  let res = await fetch(baseUrl+"/getAccountChannelInfo/"+account_channel_id);
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getAccountChannels = async (correl_id) => {
  let res = await fetch(baseUrl+"/getAccountChannels/"+correl_id);
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const updateAccountChannel = async (obj, account_channel_id) => {
  let res = await fetch(`${baseUrl}/updateAccountChannel/${account_channel_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const deleteAccountChannel = async (account_channel_id) => {
  let res = await fetch(baseUrl+"/deleteAccountChannel/"+account_channel_id, {
    method: "DELETE"
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}

// Invites
export const sendSingleInvite = async (obj) => {
  let res = await fetch(baseUrl+"/sendSingleInvite", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const sendMultipeInvites = async (obj) => {
  let res = await fetch(baseUrl+"/sendMultipleInvites", {
    method: "POST",
    body: obj
  });
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}

// Recepients
export const getMasterData = async () => {
  let res = await fetch(baseUrl+"/getMasterData/");
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}
export const getAccountData = async (correl_id) => {
  let res = await fetch(baseUrl+"/getAccountData/"+correl_id);
  if (!res.ok) {
    let data = await res.json();
    data.status = 'F';
    data.apiStatus = res.status;
    console.log(data)
    return data;
  }
  let data = await res.json();
  return data;
}