import React, { useEffect, useState } from 'react'
import "./reviews.scss"
import { FaQuestionCircle } from 'react-icons/fa'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdStar, MdStarOutline } from 'react-icons/md'
import axios from 'axios';
import moment from 'moment';
import Swal from 'sweetalert2';

function Reviews() {
    const [searchQuery, setSearchQuery] = useState('');
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [reviewId, setReviewId] = useState('');
    const [comment, setComment] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [totalPages, setTotalPages] = useState(null);
    const [content, setContent] = React.useState([]);

    const filteredData = [...content].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    // const handleSearchChange = event => {
    //     setSearchQuery(event.target.value);
    //     setCurrentPage(1); // Reset current page when the search query changes
    // };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('idToken');
        localStorage.removeItem('user');
    };

    const refreshAccessToken = async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
            try {
                const res = await axios.post('https://goodreviews.co/api/refresh-token', { refreshToken });
                const { accessToken, idToken } = res.data;
                console.log(res.data, 'refreshData')
                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('idToken', idToken);
                return { accessToken, idToken };
            } catch (error) {
                console.error('Failed to refresh access token', error);
                handleLogout();
            }
        } else {
            handleLogout();
        }
    };

    const getReviews = async () => {
        Swal.fire({
            html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
            customClass: { popup: 'loader' },
            showConfirmButton: false,
            heightAuto: false
        })
        let { accessToken, idToken } = await refreshAccessToken();
        let userId = JSON.parse(localStorage.getItem('user') || '{}').sub;
        try {
            const res = await axios.get('https://goodreviews.co/api/getReviews/' + userId, {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    Accesstoken: accessToken
                },
            });
            //   return res.data;
            setContent(res.data.reviews);
            setTotalPages(Math.ceil(res.data.reviews.length / 10))
        } catch (error) {
            console.error('Failed to make authenticated API call', error);
        } finally {
            Swal.close()
        }
    };

    const updateReply = async () => {
        Swal.fire({
            html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
            customClass: { popup: 'loader' },
            showConfirmButton: false,
            heightAuto: false
        })
        let { accessToken, idToken } = await refreshAccessToken();
        let userId = JSON.parse(localStorage.getItem('user') || '{}').sub;
        const res = await axios.post('https://goodreviews.co/api/updateReview',
            { userId, reviewId, comment },
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    Accesstoken: accessToken
                }
            }
        );
        console.log(res);
        if (res.data.comment) {
            Swal.fire({
                text: 'Success',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                heightAuto: false
            }).then(() => {
                setShowUpdateModal(false);
                setComment('');
                setReviewId('');
                getReviews()
            })
        } else {
            Swal.close()
        }
    }

    useEffect(() => {
        (async () => {
            setSearchQuery('');
            if (localStorage.getItem('refreshToken') || localStorage.getItem('idToken')) {
                Swal.fire({
                    html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
                    customClass: { popup: 'loader' },
                    showConfirmButton: false,
                    heightAuto: false
                })
                let { accessToken, idToken } = await refreshAccessToken();
                let userId = JSON.parse(localStorage.getItem('user') || '{}').sub;
                try {
                    const res = await axios.get('https://goodreviews.co/api/getReviews/' + userId, {
                        headers: {
                            Authorization: `Bearer ${idToken}`,
                            Accesstoken: accessToken
                        },
                    });
                    //   return res.data;
                    setContent(res.data.reviews);
                    setTotalPages(Math.ceil(res.data.reviews.length / 10))
                } catch (error) {
                    console.error('Failed to make authenticated API call', error);
                } finally {
                    Swal.close()
                }
            }
        })()
    }, [])

    return (
        <div className='reviews-page'>
            {showUpdateModal && <div className="overlay-review" onClick={() => {
                setShowUpdateModal(false);
                setComment('');
                setReviewId('');
            }}></div>}
            {showUpdateModal && <div className="updateReviewModal card px-4 py-3">
                <h6 className='mb-3'>Reply</h6>
                <textarea type="text" className='form-control' placeholder='Comment' value={comment} onChange={(ev) => { setComment(ev.target.value) }} />
                <div className="text-center mt-3">
                    <button className="btn btn-dark" onClick={() => { updateReply() }}>Update</button>
                </div>
            </div>}
            <div className='reviews-container'>
                <div className='headingContainer'>
                    <h5 className='heading mb-0'>Google Reviews</h5>
                    <FaQuestionCircle color='#E9C877' className='heading-icon' />
                </div>
                <p className='mb-0 reviews-desc'>List of all your reviews</p>
                <div className="reviews-card">
                    <div className="table-container">
                        <table class="table">
                            <thead className='text-start'>
                                <tr>
                                    <th scope='col'>Source</th>
                                    <th scope='col'>Date</th>
                                    <th scope='col'>From</th>
                                    <th scope='col'>Rating</th>
                                    <th scope='col'>Comment</th>
                                    <th scope='col' className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody className='justify-content-center'>
                                {paginatedData && (paginatedData.length > 0) && paginatedData.map((row, index) => (
                                    <tr className='text-start align-items-center' key={'review-' + index}>
                                        <th scope="row"><img src={
                                            row.reviewer.profilePhotoUrl
                                        } alt="" className='profile-image' /></th>
                                        <td>
                                            {moment(row.updateTime).format('DD-MMM-YYYY')}
                                        </td>
                                        <td>
                                            {row.reviewer.displayName}
                                        </td>
                                        <td>
                                            {row.starRating === 'FIVE' && <div className="d-flex">
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                            </div>}
                                            {row.starRating === 'FOUR' && <div className="d-flex">
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStarOutline color='#E9C877' fontSize={'20px'} />
                                            </div>}
                                            {row.starRating === 'THREE' && <div className="d-flex">
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStarOutline color='#E9C877' fontSize={'20px'} />
                                                <MdStarOutline color='#E9C877' fontSize={'20px'} />
                                            </div>}
                                            {row.starRating === 'TWO' && <div className="d-flex">
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStarOutline color='#E9C877' fontSize={'20px'} />
                                                <MdStarOutline color='#E9C877' fontSize={'20px'} />
                                                <MdStarOutline color='#E9C877' fontSize={'20px'} />
                                            </div>}
                                            {row.starRating === 'ONE' && <div className="d-flex">
                                                <MdStar color='#E9C877' fontSize={'20px'} />
                                                <MdStarOutline color='#E9C877' fontSize={'20px'} />
                                                <MdStarOutline color='#E9C877' fontSize={'20px'} />
                                                <MdStarOutline color='#E9C877' fontSize={'20px'} />
                                                <MdStarOutline color='#E9C877' fontSize={'20px'} />
                                            </div>}
                                        </td>
                                        <td>{row.comment}</td>
                                        <td className='text-center'>
                                            <button className='btn btn-dark px-4 rounded-pill' onClick={() => {
                                                setShowUpdateModal(true);
                                                setReviewId(row.reviewId);
                                                setComment(row.reviewReply.comment);
                                            }}>{row.reviewReply ? 'Update Reply' : 'Reply'}</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
                            <MdKeyboardArrowLeft />
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
                            <MdKeyboardArrowRight />
                        </button>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Reviews
