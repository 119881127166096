import React from 'react'
import './petservice.scss'
import Header from '../../../components/header/Header'
import { FaPlus } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import ButtonsContainer, { FreeTrailButton } from '../../../components/buttonscontainer/ButtonsContainer'
import Footer from '../../../components/footer/Footer'
import { useNavigate } from 'react-router-dom'
import SwiperComponent from '../../../components/swiper/SwiperComponent'
import { Helmet } from 'react-helmet-async'

function PetServices() {
    const navigate = useNavigate('');
    return (<>
        <Helmet>
            <title>Pet Services Review Management | America's #1 Choice for Reputation Excellence | Boost Your Reputation with Good Reviews</title>
            <meta name="description" content="Enhance your pet service business with Good Reviews' powerful reputation management tools. Build your review profile, strengthen pet owner trust, and become the go-to pet care provider in your area. Start your 30-day free trial and see how our software can accelerate your business growth." />
            <meta name="keywords" content="pet services reviews, pet care reviews, Google reviews, online reputation management, pet business marketing, pet owner feedback, review management software, local pet services, Good Reviews, veterinary reputation, grooming reviews" />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.goodreviews.co/verticals/pet-services" />
            <meta property="og:title" content="Elevate Your Pet Service Business with Good Reviews Management" />
            <meta property="og:description" content="Boost your online reputation, increase Google reviews, and become the top pet care provider in your area. Try Good Reviews' powerful tools with a 30-day free trial.  !" />
            <meta property="og:image" content="https://www.goodreviews.co/logo.png" />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.goodreviews.co/verticals/pet-services" />
            <meta property="twitter:title" content="Elevate Your Pet Service Business with Good Reviews Management" />
            <meta property="twitter:description" content="Boost your online reputation, increase Google reviews, and become the top pet care provider in your area. Try Good Reviews' powerful tools with a 30-day free trial.  !" />
            <meta property="twitter:image" content="https://www.goodreviews.co/logo.png" />
        </Helmet>
        <div className='bootstrap-content petservices'>
            <div className="bg-white">
                <Header />
                <section className='section-padding'>
                    <div className="container-fluid">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-lg-5 mb-lg-0 mb-3">
                                <div className="text-center">
                                    <img src={require('../../../assets/pet-services.png')} alt="" className='main-image' />
                                </div>
                            </div>
                            <div className="col-12 col-lg-7 px-lg-4">
                                <p className='banner-heading'>Pet Services</p>
                                <p className='mb-0 banner-description'>Enhance the trust and credibility of your pet services business with the power of authentic client reviews! At Good Reviews, we specialize in helping pet service providers showcase their dedication to quality care through genuine feedback from happy pet owners. Our platform streamlines the process of collecting and displaying real testimonials, allowing you to highlight your commitment to exceptional service and the well-being of pets.</p>
                                <p className='mb-0 banner-description'>By promoting positive reviews, you can attract new clients and establish yourself as a trusted expert in the pet care industry. Whether you offer grooming, boarding, training, or veterinary services, let us assist you in growing your business and building lasting relationships with pet parents!</p>
                                <div className="d-flex mt-4">
                                    <FreeTrailButton />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section className='cards-section'>
                <div className="container">
                    <div className="row justify-content-center">
                        {/* <div className="col-12 col-lg-3">
                            <div className='rating-card'>
                                <div className="rating-card-bg-white">
                                    <p className='rating-card-bg-white-content mb-0'>With Good Reviews</p>
                                </div>
                                <p className='mb-0 rating-card-heading'>206</p>
                                <div className="d-flex align-items-center justify-conyent-center g-0">
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                </div>
                                <p className='mb-0 rating-card-description'>Google Reviews</p>
                                <div className="rating-card-bg-white-bottom mt-2">
                                    <p className='rating-card-bg-white-content mb-0'>Before 24</p>
                                </div>
                            </div>
                            <div className='text-center'>
                                <img src={require('../../../assets/amazon.png')} alt="" className='rating-card-image' />
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className='rating-card'>
                                <div className="rating-card-bg-white">
                                    <p className='rating-card-bg-white-content mb-0'>With Good Reviews</p>
                                </div>
                                <p className='mb-0 rating-card-heading'>330</p>
                                <div className="d-flex align-items-center justify-conyent-center g-0">
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                </div>
                                <p className='mb-0 rating-card-description'>Google Reviews</p>
                                <div className="rating-card-bg-white-bottom mt-2">
                                    <p className='rating-card-bg-white-content mb-0'>Before 64</p>
                                </div>
                            </div>
                            <div className='text-center'>
                                <img src={require('../../../assets/google.png')} alt="" className='rating-card-image' />
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className='rating-card'>
                                <div className="rating-card-bg-white">
                                    <p className='rating-card-bg-white-content mb-0'>With Good Reviews</p>
                                </div>
                                <p className='mb-0 rating-card-heading'>420</p>
                                <div className="d-flex align-items-center justify-conyent-center g-0">
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                </div>
                                <p className='mb-0 rating-card-description'>Google Reviews</p>
                                <div className="rating-card-bg-white-bottom mt-2">
                                    <p className='rating-card-bg-white-content mb-0'>Before 32</p>
                                </div>
                            </div>
                            <div className='text-center align-items-center justify-content-cenetr'>
                                <img src={require('../../../assets/tripadvisoricon.png')} alt="" className='rating-card-icon-image' />
                                <img src={require('../../../assets/tripadvisor.png')} alt="" className='rating-card-icon-mainimage ' />
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className='rating-card'>
                                <div className="rating-card-bg-white">
                                    <p className='rating-card-bg-white-content mb-0'>With Good Reviews</p>
                                </div>
                                <p className='mb-0 rating-card-heading'>930</p>
                                <div className="d-flex align-items-center justify-conyent-center g-0">
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                    <img src={require('../../../assets/star.png')} alt="" className='star-image' />
                                </div>
                                <p className='mb-0 rating-card-description'>Google Reviews</p>
                                <div className="rating-card-bg-white-bottom mt-2">
                                    <p className='rating-card-bg-white-content mb-0'>Before 32</p>
                                </div>
                            </div>
                            <div className='text-center'>
                                <img src={require('../../../assets/trustpilot.png')} alt="" className='rating-card-image' />
                            </div>
                        </div> */}
                        <SwiperComponent />
                    </div>
                </div>
            </section>
            <section className='good-reviews-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 justify-content-center">
                            <h6 className='section-heading'>Why Good Reviews Company ?</h6>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                            <div className="good-reviews-bg-black">
                                <div className="d-flex align-items-center mb-0">
                                    <p className='mb-0 good-reviews-heading'>1.9M</p>
                                    <FaPlus fontSize={'20px'} color='#fff' />
                                </div>
                                <p className='good-reviews-desc mb-0'>Reviews enabled</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                            <div className="good-reviews-bg-black">
                                <div className="d-flex align-items-center mb-0">
                                    <p className='mb-0 good-reviews-heading'>70,000</p>
                                    <FaPlus fontSize={'20px'} color='#fff' />
                                </div>
                                <p className='good-reviews-desc mb-0'>Businesses served</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                            <div className="good-reviews-bg-black">
                                <div className="d-flex align-items-center mb-0">
                                    <p className='mb-0 good-reviews-heading'>5</p>
                                    <AiOutlineClose fontSize={'20px'} color='#fff' />
                                </div>
                                <p className='good-reviews-desc mb-0'>Increase in reviews</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                            <div className="good-reviews-bg-black">
                                <div className="d-flex align-items-center mb-0">
                                    <p className='mb-0 good-reviews-heading'>4.8</p>
                                    {/* <div className="d-flex align-items-center g-0">
                                        <img src={require('../../../assets/star.png')} alt="" className='position-star-image' />
                                        <img src={require('../../../assets/star.png')} alt="" className='position-star-image' />
                                        <img src={require('../../../assets/star.png')} alt="" className='position-star-image' />
                                        <img src={require('../../../assets/star.png')} alt="" className='position-star-image' />
                                        <img src={require('../../../assets/star.png')} alt="" className='position-star-image' />
                                    </div> */}
                                </div>
                                <p className='good-reviews-desc mb-0'>Google review rating</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content-section'>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-10">
                        <h6 className='mb-0 content-section-heading'>Grow your business faster with Good Reviews</h6>
                        <p className='mb-0 content-section-desc'>Try Good Reviews today with a 14-day free trial and see how reputation marketing software will help you grow.No credit card required.</p>
                        <div className="py-lg-4">
                            <ButtonsContainer navigate={navigate} />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    </>)
}

export default PetServices
