import { useEffect, useRef, useState } from "react";

const SearchableDropdown = ({
  options,
  label,
  id,
  selectedVal,
  handleChange
}) => {
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => "");
    handleChange(option);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal.company_name;

    return "";
  };

  const filter = (options) => {
    if(query){
      return options.filter(
        (option) => option.company_name.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    }else{
      return options
    }
  };

  return (
    <div className="dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            placeholder="--Select Company--"
            autoComplete="off"
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(null);
            }}
            onClick={toggle}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        <div
          onClick={() => selectOption({correl_id: '', company_name: 'All'})}
          className={`option ${(selectedVal && selectedVal.correl_id === '') ? "selected" : ""}`}
        >
          All
        </div>
        {!query && filter(options).map((option, index) => {
          return (
            <div
              onClick={() => selectOption(option)}
              className={`option ${
                option['correl_id'] === (selectedVal && selectedVal.correl_id) ? "selected" : ""
              }`}
              key={`${id}-${index}`}
            >
              {option.company_name}
            </div>
          );
        })}
        {query && filter(options).map((option, index) => {
          return (
            <div
              onClick={() => selectOption(option)}
              className={`option ${
                option['correl_id'] === (selectedVal && selectedVal.correl_id) ? "selected" : ""
              }`}
              key={`${id}-${index}`}
            >
              {option.company_name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchableDropdown;