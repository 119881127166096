import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2';
import { getChannelInfo, updateChannel } from '../../../../Api';
import { useNavigate, useParams } from 'react-router-dom';

const InputField = ({ disabled, value, valueFunc, label }) => {
  const inputRef = useRef(null);
  const floatRef = useRef(null);

  return (
    <>
      <span ref={floatRef} onClick={() => {
        if (!disabled && inputRef.current) {
          inputRef.current.focus();
        }
      }} className={value ? 'float' : ''}>{label}</span>
      <input disabled={disabled} ref={inputRef} type={"text"} className='w-100'
        onFocus={() => {
          if (floatRef.current) {
            floatRef.current.className = "float"
          }
        }}
        onBlur={() => {
          if (value) {
            if (floatRef.current) {
              floatRef.current.className = "float"
            }
          } else {
            if (floatRef.current) {
              floatRef.current.className = ""
            }
          }
        }}
        value={value}
        onChange={(ev) => { valueFunc(ev.target.value) }}
      />
    </>
  )
}

function EditChannel() {
  const navigate = useNavigate();
  const { channel_id } = useParams();
  const [channelName, setChannelName] = useState('');

  const submit = async () => {
    if (!channelName) {
      Swal.fire({
        text: 'Channel name is required',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 2000
      })
      return;
    } else {
      let data = { channel_name: channelName }
      let apiRes = await updateChannel(data, channel_id);
      if (apiRes.status === "S") {
        Swal.fire({
          text: 'Channel updated successfully',
          icon: 'success',
          iconColor: '#000',
          showConfirmButton: false,
          heightAuto: false,
          timer: 2000
        }).then(() => {
          navigate('/empower/manage-channels')
        })
      }
    }
  }

  useEffect(() => {
    (async () => {
      let apiRes = await getChannelInfo(channel_id);
      if (apiRes.status === 'S') {
        setChannelName(apiRes.result_info.channel_name);
      }
    })()
  }, [channel_id])

  return (
    <div className="bootstrap-content custom-page">
      <h5 className='heading mb-4'>Edit Account</h5>
      <div className="ui-card">
        <div className="row">
          <div className="col-12">
            <div className="input-field">
              <InputField disabled={false} required={true} value={channelName} valueFunc={(val) => {
                setChannelName(val)
              }} label={'Channel Name *'} />
            </div>
          </div>
          <div className="col-12">
            <div className="text-center mt-3">
              <button className='btn btn-dark px-4' disabled={!channelName} onClick={() => {
                submit();
              }}>Update</button>
              <button className='btn btn-dark px-4 ms-3' onClick={() => {
                navigate('/empower/manage-channels')
              }}>Go Back</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditChannel