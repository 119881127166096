import React from 'react'
import "./reporting.scss"
import { MdStar } from 'react-icons/md'
import { FaTree } from 'react-icons/fa'
import HorizontalBarChart from './components/horizontalchart/HorizontalBarChart'
import GaugeChart from './components/gaugechart/GaugeChart'

function Reporting() {
    return (
        <div className='reporting-page'>
            <div className="report-container">
                <h5 className='report-heading'>Reporting</h5>
                <p className='report-desc'>Reviews analytics</p>
                <div className="row align-items-center">
                    <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                        <div className="report-card">
                            <h6 className="card-text">
                                TOTAL REVIEWS
                            </h6>
                            <div className="card-body p-0">
                                <MdStar className='card-icon' />
                                <p className='mt-2 mb-0'>30</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                        <div className="report-card">
                            <h6 className="card-text">
                                TREES PLANTED
                            </h6>
                            <div className="card-body p-0">
                                <FaTree className='card-tree-icon' />
                                <p className='mt-2 mb-0'>1</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 mb-3 mb-lg-0">
                        <div className='text-center'>
                            <div className="card-body p-0">
                                <p className="card-text">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatem aut, cumque soluta ad vitae blanditiis! Accusantium, quisquam porro aperiam minima at nam illum saepe! Magnam!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-5">
                    <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                        <div className="report-card h-100">
                            <h6 className='card-text text-center'>RATING DISTRIBUTION</h6>
                            <HorizontalBarChart />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                        <div className="report-card h-100 p-4">
                            <h6 className='card-text text-center'>AVERAGE RATING</h6>
                            <GaugeChart value={4} />
                            {/* <img src={require('../../../../assets/rating-grad.PNG')} alt="" className='rating-grad-image' /> */}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Reporting
