import React, { useState } from 'react'
import "../../css/bootstrap.scss"
import './accountverification.scss'
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyOtp } from '../../../Api';
import Swal from 'sweetalert2';

function AccountVerification() {
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email || '';
    const correl_id = location.state?.correl_id || '';

    const [otp, setOtp] = useState('');

    const submit = async () => {
        Swal.fire({
            html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
            customClass: {popup: 'loader'},
            showConfirmButton: false,
            heightAuto: false
        })
        if(!otp){
            Swal.fire({
                text: 'Verification code required!',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        }else{
            let apiRes = await verifyOtp({otp, correl_id});
            if(apiRes.status === 'S'){
                Swal.fire({
                    text: 'Verification successful. Redirecting to login',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 2000
                }).then(() => {
                    navigate('/grow')
                })
            }else{
                Swal.fire({
                    text: 'Verification failed!',
                    icon: 'warning',
                    iconColor: '#000',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 2000
                })
            }
        }
    }

    return (
        <div className='bootstrap-content forgot-page'>
            {/* <div className="row justify-content-lg-start g-0">
                <div className="col-12">
                    <div className="header-text">
                        <h6 className='mb-0'>GOOD REVIEWS</h6>
                        <img src={require('../../assets/logo.png')} alt="" />
                    </div>
                </div>
            </div> */}
            <div className="row g-0 w-100 h-100" >
                <div className="col-12 col-lg-6 d-none d-lg-block">
                    {/* <div className="forgot-left">
                        <div className="ellipse"></div>
                    </div> */}
                    <div className="forgot-left">
                        <div className="ellipse"></div>
                        <h6 className='forgot-heading'>Tell Your Reviews </h6>
                        <h6 className='forgot-subheading'>We Make It Global</h6>
                        <div className="bg-color">
                            <div className="image-container">
                                <img src={require('../../assets/video-file.png')} alt="" />
                            </div>
                        </div>
                        <h6 className='login-desc mt-3'>Trusted By 10,000+ Global Companies of all sizes</h6>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/aws.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/microsoft.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/google-partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/katalon.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/ware.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/citrix.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/salesforce.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/norton.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/symantec.png')} alt="" className='logos' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6">
                    <div className="forgot-right">
                        <div className="forgot-card">
                            <h6 className='forgot-card-heading'>Check Your email</h6>
                            <p className='forgot-card-desc'>Please enter the verification code we sent to <br className='d-none d-lg-block' />
                                {email}
                            </p>
                            <div className='row justify-content-start'>
                                <div className="col-12">
                                    <div className="mb-3 text-start">
                                        <label for="exampleInputEmail1" className="form-label">Verification code</label>
                                        <input type="email" className="form-control" id="exampleInputEmail1" value={otp} onChange={(ev) => {setOtp(ev.target.value)}} aria-describedby="emailHelp" />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="my-3 text-start">
                                        <button className='btn btn-black' onClick={() => {submit()}}>Verify Code</button>
                                    </div>
                                    <p className='back-text'>Can't find the email? <span className='border-bottom-black'>Resend code</span></p>
                                </div>
                                <div className="col-12">
                                    <h6 className='bottom-text'>By signing up to the Good Reviews  platform you understand and agree with our <br />
                                        <span>Customer Terms of Service </span> and <span>Privacy Policy</span></h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccountVerification
