import React, { useState } from 'react'
// import "./weblogin.scss"
import { useNavigate } from 'react-router-dom'
import { changePassword } from '../../../Api';
import Swal from 'sweetalert2';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

function ChangePassword() {
    const navigate = useNavigate();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const handleKeyDown = (ev) => {
        if (ev.key === "Enter") {
            ev.preventDefault();
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        Swal.fire({
            html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
            customClass: { popup: 'loader' },
            showConfirmButton: false,
            heightAuto: false
        })
        if (!oldPassword) {
            Swal.fire({
                text: 'Old password required!',
                showConfirmButton: false,
                icon: 'warning',
                iconColor: '#000',
                timer: 2000,
            })
            return;
        } else if (!newPassword) {
            Swal.fire({
                text: 'New password required!',
                showConfirmButton: false,
                icon: 'warning',
                iconColor: '#000',
                timer: 2000,
            })
            return;
        } else if (oldPassword === newPassword) {
            Swal.fire({
                text: 'New password should not match with old password!',
                showConfirmButton: false,
                icon: 'warning',
                iconColor: '#000',
                timer: 2000,
            })
            return;
        } else if (!confirmPassword) {
            Swal.fire({
                text: 'Confirm password required!',
                showConfirmButton: false,
                icon: 'warning',
                iconColor: '#000',
                timer: 2000,
            })
            return;
        } else if (confirmPassword !== newPassword) {
            Swal.fire({
                text: 'New password should match with confirm password!',
                showConfirmButton: false,
                icon: 'warning',
                iconColor: '#000',
                timer: 2000,
            })
            return;
        } else {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let correl_id = localStorage.getItem('correl_id');
            let data = {
                correl_id,
                oldPassword,
                newPassword,
            }
            let apiRes = await changePassword(data);
            console.log(apiRes)
            if (apiRes.status === "S") {
                Swal.fire({
                    text: 'Your password changed successfully. Redirecting to login',
                    icon: 'success',
                    showConfirmButton: false,
                    heightAuto: false,
                    timer: 2000
                }).then(() => {
                    if (userData.account_code === 'prod-owner') {
                        navigate('/empower')
                    } else {
                        navigate('/grow')
                    }
                })
            } else if (apiRes.status === 'E' && apiRes.result_code === 404) {
                Swal.fire({
                    text: 'User not found',
                    showConfirmButton: false,
                    icon: 'warning',
                    iconColor: '#000',
                    timer: 2000,
                })
            } else if (apiRes.status === 'E' && apiRes.result_code === 401) {
                Swal.fire({
                    text: 'Invalid Old password',
                    showConfirmButton: false,
                    icon: 'warning',
                    iconColor: '#000',
                    timer: 2000,
                })
            } else {
                Swal.close();
            }
        }
    }

    return (
        <div className='bootstrap-content weblogin-page'>
            <div className="row g-0 w-100 h-100" >
                <div className="col-6 d-none d-lg-block">
                    <div className="login-left">
                        <div className="ellipse"></div>
                        <h6 className='login-heading'>Tell Your Reviews </h6>
                        <h6 className='login-subheading'>We Make It Global</h6>
                        <div className="bg-color">
                            <div className="image-container">
                                <img src={require('../../assets/video-file.png')} alt="" />
                            </div>
                        </div>
                        <h6 className='login-desc mt-3'>Trusted By 10,000+ Global Companies of all sizes</h6>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/aws.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/microsoft.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/google-partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/katalon.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/partner.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/ware.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/citrix.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/salesforce.png')} alt="" className='logos' />
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col mb-2">
                                <img src={require('../../assets/norton.png')} alt="" className='logos' />
                            </div>
                            <div className="col mb-2">
                                <img src={require('../../assets/symantec.png')} alt="" className='logos' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 h-100">
                    <div className="login-right">
                        <div className="login-card">
                            <div className="text-center">
                                <img src={require('../../assets/logo.png')} alt="" className='logo-image' />
                                <h6 className='login-welcometext'>Change Password</h6>
                            </div>
                            <div className="text-start">
                                <div className="mb-3 text-start">
                                    <label for="exampleInputPassword1" className="form-label">Old Password</label>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <input type={showPassword ? "text" : 'password'}
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            autoComplete="off"
                                            placeholder='Enter old Password'
                                            value={oldPassword}
                                            onChange={(e) => setOldPassword(e.target.value)}
                                        />
                                        <span
                                            className="input-icon"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                        </span>
                                    </div>

                                </div>
                                <div className="mb-3 text-start">
                                    <label for="exampleInputPassword1" className="form-label">New Password</label>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <input type={showPassword1 ? "text" : 'password'}
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            autoComplete="off"
                                            placeholder='Enter new Password'
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                        <span
                                            className="input-icon"
                                            onClick={() => setShowPassword1(!showPassword1)}
                                        >
                                            {showPassword1 ? <MdVisibility /> : <MdVisibilityOff />}
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-3 text-start">
                                    <label for="exampleInputPassword1" className="form-label">Confirm Password</label>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <input type={showPassword2 ? "text" : 'password'}
                                            className="form-control"
                                            id="exampleInputPassword1"
                                            autoComplete="off"
                                            placeholder='Re-enter new Password'
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <span
                                            className="input-icon"
                                            onClick={() => setShowPassword2(!showPassword2)}
                                        >
                                            {showPassword2 ? <MdVisibility /> : <MdVisibilityOff />}
                                        </span>
                                    </div>

                                </div>
                                <button className='text-center btn btn-black' onClick={() => { handleSubmit() }}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ChangePassword
