import React from 'react'
import './goodreviewslocal.scss'
import Header from '../../../components/header/Header'
import { useNavigate } from 'react-router-dom'
import ButtonsContainer from '../../../components/buttonscontainer/ButtonsContainer';
import Footer from '../../../components/footer/Footer';
import { Helmet } from 'react-helmet-async';

function GoodReviewsLocal() {
    const navigate = useNavigate();

    return (<>
        <Helmet>
            <title>Good Reviews Local | America's #1 Choice for Reputation Excellence | Boost Your Online Reputation & Grow Your Customer Base</title>

            <meta name="description" content="Supercharge your local online presence with Good Reviews Local. Manage reviews, respond quickly to feedback, and monitor sentiment trends. With 97% of customers learning about local businesses online, enhance your visibility and attract more customers today." />

            <meta name="keywords" content="online reputation management, local business marketing, customer reviews, review management, business growth, local SEO, customer feedback, review monitoring, sentiment analysis, review response" />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://www.goodreviews.co/capabilities/good-reviews-local" />
            <meta property="og:title" content="Good Reviews Local: Supercharge Your Online Reputation & Customer Growth" />
            <meta property="og:description" content="Boost your local business visibility, manage reviews effortlessly, and attract more customers. With Good Reviews Local, respond quickly to feedback and monitor sentiment trends. Start your free trial now!" />
            <meta property="og:image" content="https://www.goodreviews.co/logo.png" />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://www.goodreviews.co/capabilities/good-reviews-local" />
            <meta property="twitter:title" content="Good Reviews Local: Supercharge Your Online Reputation & Customer Growth" />
            <meta property="twitter:description" content="Boost your local business visibility, manage reviews effortlessly, and attract more customers. With Good Reviews Local, respond quickly to feedback and monitor sentiment trends. Start your free trial now!" />
            <meta property="twitter:image" content="https://www.goodreviews.co/logo.png" />

        </Helmet>
        <div className='bootstrap-content good-reviews-local'>
            <div className="header-sec">
                <div className="bg-white">
                    <Header />
                </div>
            </div>
            <section className='section-padding banner-bg'>
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 text-center">
                            <h1 className='banner-sub-heading text-white'>Good Reviews Local</h1>
                            <h3 className='banner-heading text-white'>Reach More Customers</h3>
                            <p className='banner-text text-white'>Boost your online reputation and watch your customer base grow with Good Reviews</p>
                            <div className='text-center'>
                                <button className='banner-btn-white' onClick={() => { navigate('/pricing') }}>Start Free Trail</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-padding'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3 className='about-heading'>97% of people learn about a local business online.</h3>
                            <p className='mb-0 about-text'>Good Reviews supercharges your online presence, attracting more customers by boosting your visibility <br className='d-none d-lg-block' /> on the platforms that matter most to your target audience</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-padding'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-7">
                            <h3 className='list-heading'>Robust Review Management</h3>
                            <h3 className='list-brief-text'>Did you know only 19% of businesses on Google respond to reviews? Solid review management not only drives more business, but can also impact your rankings and visibility.</h3>
                            <h3 className='list-main-heading'>Key Features:</h3>
                            <div className='list-el'>
                                <div>
                                    <img src={require("../../../assets/logo-fav.png")} className='list-left-image' alt="" />
                                </div>
                                <div>
                                    <h4 className='list-sub-heading'>Reputation Management:</h4>
                                    <p className='mb-0 list-text'>Monitor, read, and respond to reviews on multiple sites from a single dashboard. Respond quickly to negative feedback to create a positive customer experience and retain customers.</p>
                                </div>
                            </div>
                            <div className='list-el'>
                                <div>
                                    <img src={require("../../../assets/logo-fav.png")} className='list-left-image' alt="" />
                                </div>
                                <div>
                                    <h4 className='list-sub-heading'>Alerts & Notifications:</h4>
                                    <p className='mb-0 list-text'>Improve response times with instant alerts of new customer reviews about your business on the major sites.</p>
                                </div>
                            </div>
                            <div className='list-el'>
                                <div>
                                    <img src={require("../../../assets/logo-fav.png")} className='list-left-image' alt="" />
                                </div>
                                <div>
                                    <h4 className='list-sub-heading'>Review Sentiment and Trends:</h4>
                                    <p className='mb-0 list-text'>Monitor review sentiment, common keywords in reviews, and customer feedback over time.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="text-center text-lg-end">
                                <img src={require('../../../assets/capabilites/good-reviews-local.png')} alt="" className='main-image side-image' />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-5">
                        <div className="col-12">
                            <div className="py-lg-4">
                                <ButtonsContainer navigate={navigate} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    </>)
}

export default GoodReviewsLocal
