import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./manageaccountchannels.scss";
import Swal from "sweetalert2";
import { deleteAccountChannel, getAccountChannels } from "../../../../Api";
import { MdAdd, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

function ManageAccountChannels() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [totalPages, setTotalPages] = useState(null);
  const [content, setContent] = React.useState([]);
  const tableRef = useRef(null)

  const filteredData = [...content].filter((row) =>
    Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset current page when the search query changes
  };

  const paginatedData = [...filteredData].slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const edit = (correl_id) => {
    navigate("/grow/edit-account-channel/" + correl_id);
  };
  const deleteFunc = (account_channel_id) => {
    Swal.fire({
      text: `Are you sure you want to delete the channel?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
    }).then(async (res) => {
      if (res.isConfirmed) {
        let apiRes = await deleteAccountChannel(account_channel_id);
        console.log(apiRes);
        if (apiRes.status === "S") {
          Swal.fire({
            text: `Channel Deleted`,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            fetchData();
          });
        }
      }
    });
  };

  const fetchData = async () => {
    let userData = JSON.parse(localStorage.getItem('userData') || '{}');
    let apiData = await getAccountChannels(userData.correl_id);
    apiData.status === "S" ? setContent(apiData.result_info) : setContent([]);
    apiData.status === "S"
      ? setTotalPages(Math.ceil([...apiData.result_info].length / pageSize))
      : setTotalPages(0);
  };

  useEffect(() => {
    (async () => {
      let userData = JSON.parse(localStorage.getItem('userData') || '{}');
      let apiData = await getAccountChannels(userData.correl_id);
      apiData.status === "S" ? setContent(apiData.result_info) : setContent([]);
      apiData.status === "S"
        ? setTotalPages(Math.ceil([...apiData.result_info].length / pageSize))
        : setTotalPages(0);
    })();
  }, [navigate]);

  return (
    // <div className="recepients-page">
    //   <div className='recepients-container'>
    //   <h5 className="page-heading">Manage Channels</h5>
    //   <div className="d-flex align-items-end justify-content-between mt-3">
    //     <div className="search-field">
    //       <span ref={labelRef} onClick={() => {
    //         if(inputRef.current){
    //           inputRef.current.focus();
    //         }
    //       }} className={searchQuery ? 'float': ''}>Search</span>
    //       <input ref={inputRef} type={"text"} className='w-100'
    //         onFocus={() => {
    //           if(labelRef.current){
    //             labelRef.current.className = "float"
    //           }
    //         }}
    //         onBlur={() => {
    //           if(searchQuery){
    //             if(labelRef.current){
    //               labelRef.current.className = "float"
    //             }
    //           }else{
    //             if(labelRef.current){
    //               labelRef.current.className = ""
    //             }
    //           }
    //         }} 
    //         value={searchQuery}
    //         onChange={handleSearchChange}
    //       />
    //     </div>
    //     <button className="btn btn-dark px-4 d-flex align-items-center" onClick={() => {navigate('/grow/create-account-channel')}}><MdAdd /> <span className="ms-2">Create</span></button>
    //   </div>
    //   <div className="my-3">
    //       <button className="btn btn-export px-4 me-3" onClick={handleExportPDF}>PDF</button>
    //       <button className="btn btn-export px-4" onClick={handleExportCSV}>EXCEL</button>
    //     </div>
    //   <div className="table-card p-0">
    //     <div className="tableContainer common-table">
    //       <table className="table" ref={tableRef}>
    //         <thead>
    //           <tr>
    //             <th>Channel Name</th>
    //             <th className="col-width">Actions</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {paginatedData &&
    //             paginatedData.length > 0 &&
    //             paginatedData.map((row, index) => (
    //               <tr key={index}>
    //                 <td>{row.channel_name}</td>
    //                 <td className="col-width">
    //                   <button
    //                     className="btn btn-dark px-4 me-2"
    //                     onClick={() => {
    //                       edit(row.account_channel_id);
    //                     }}
    //                   >
    //                     Edit
    //                   </button>
    //                   <button
    //                     className={'btn px-3 btn-danger'}
    //                     onClick={() => {
    //                       deleteFunc(row.account_channel_id);
    //                     }}
    //                   >
    //                     Delete
    //                   </button>
    //                 </td>
    //               </tr>
    //             ))}
    //         </tbody>
    //       </table>
    //       <div className="d-flex justify-content-end align-items-center table-footer">
    //         <button
    //           className="btn btn-light me-2"
    //           onClick={previousPage}
    //           disabled={currentPage === 1}
    //         >
    //           <MdChevronLeft size={20} />
    //         </button>
    //         <span>
    //           Page {currentPage} of {totalPages ? totalPages : 1}
    //         </span>
    //         <button
    //           className="btn btn-light ms-2"
    //           onClick={nextPage}
    //           disabled={currentPage === totalPages}
    //         >
    //           <MdChevronRight size={20} />
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    //   </div>
    // </div>
    <div className='recepients-page'>
      <div className='recepients-container'>
        <div className='headingContainer'>
          <h5 className='heading mb-0'>Channels</h5>
        </div>
        <div className='d-flex justify-content-between align-items-center my-3'>
          <input type="text" placeholder="Search" className='searchInput py-1' value={searchQuery} onChange={handleSearchChange} />
          <button className="btn btn-dark px-4 py-1 d-flex align-items-center" onClick={() => { navigate('/grow/create-account-channel') }}><MdAdd /> <span className="ms-2">Create</span></button>
        </div>
        {/* <div className='d-flex align-items-center mt-3'>
            <button className='btn btn-white me-3' onClick={handleExportPDF}>
                PDF
            </button>
            <button className='btn btn-white' onClick={handleExportCSV}>
                EXCEL
            </button>
        </div> */}
        <div className="recepients-card ">
          <div className="table-container">
            <table class="table" ref={tableRef}>
              <thead>
                <tr>
                  <th scope='col'>Channel Name</th>
                  <th scope='col'>Channel Url</th>
                  <th scope='col'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData && (paginatedData.length > 0) && paginatedData.map((row, index) => (
                  <tr>
                    <td>{row.channel_name}</td>
                    {/* <td>{row.channel_url}</td> */}
                    <td className="channle_url_td">
                      <div className="channel_url">
                        {row.channel_url}
                        {/* https://www.google.com/search?q=rivirtual&oq=rivirtual&gs_lcrp=EgZjaHJvbWUqCggAEAAY4wIYgAQyCggAEAAY4wIYgAQyBggBEEUYPDIGCAIQRRhBMg0IAxAuGK8BGMcBGIAEMgcIBBAAGIAEMgYIBRBFGEEyBggGEEUYPDIGCAcQRRhB0gEJMTI2NjNqMGo0qAIAsAIB&sourceid=chrome&ie=UTF-8#lrd=0x3bcb933f697df7b7:0x81be510ea5581b22,1 */}
                      </div>
                    </td>
                    <td>
                      <button
                        className="btn btn-dark px-4 me-2"
                        onClick={() => {
                          edit(row.account_channel_id);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className={'btn px-3 btn-danger'}
                        onClick={() => {
                          deleteFunc(row.account_channel_id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
                {(!paginatedData || (paginatedData.length === 0)) &&
                  <tr>
                    <td>No channel found</td>
                    <td></td>
                  </tr>
                }
              </tbody>
            </table>

          </div>
          <div className="d-flex justify-content-end align-items-center mt-3">
            <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
              <MdKeyboardArrowLeft />
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
              <MdKeyboardArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div >
  );
}

export default ManageAccountChannels;