import React from 'react'
import './companylogos.scss'

function CompanyLogos() {
    return (
        <div className='logos-page'>
            <div className="row align-items-center">
                <div className="col-12 col-lg-3 mb-4 company-logos">
                    <img src={require('../../assets/aws.png')} alt="" className='partners-image' />
                </div>
                <div className="col-12 col-lg-3 mb-4 company-logos">
                    <img src={require('../../assets/microsoft.png')} alt="" className='partners-image' />
                </div>
                <div className="col-12 col-lg-3 mb-4 company-logos">
                    <img src={require('../../assets/google-partner.png')} alt="" className='partners-image' />
                </div>
                <div className="col-12 col-lg-3 mb-4 company-logos">
                    <img src={require('../../assets/katalon.png')} alt="" className='partners-image' />
                </div>
                <div className="col-12 col-lg-3 mb-4 company-logos">
                    <img src={require('../../assets/partner.png')} alt="" className='partners-image' />
                </div>
                <div className="col-12 col-lg-3 mb-4 company-logos">
                    <img src={require('../../assets/ware.png')} alt="" className='partners-image' />
                </div>
                <div className="col-12 col-lg-3 mb-4 company-logos">
                    <img src={require('../../assets/citrix.png')} alt="" className='partners-image' />
                </div>
                <div className="col-12 col-lg-3 mb-4 company-logos">
                    <img src={require('../../assets/salesforce.png')} alt="" className='partners-image' />
                </div>
                <div className="col-12 col-lg-3 mb-4 company-logos">
                    <img src={require('../../assets/cxo-magazine.png')} alt="" className='partners-image' />
                </div>
                <div className="col-12 col-lg-3 mb-4 company-logos">
                    <img src={require('../../assets/norton.png')} alt="" className='partners-image' />
                </div>
                <div className="col-12 col-lg-3 mb-4 company-logos">
                    <img src={require('../../assets/symantec.png')} alt="" className='partners-image' />
                </div>
                <div className="col-12 col-lg-3 mb-4 company-logos">
                    <img src={require('../../assets/carousel-images/carousel-image-10.png')} alt="" className='partners-image' />
                </div>
            </div>
        </div>
    )
}

export default CompanyLogos
