import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./manage.scss";
import Swal from "sweetalert2";
import { deleteChannel, getChannels } from "../../../../Api";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

function ManageChannels() {
  const navigate = useNavigate();
  const labelRef = useRef(null)
  const inputRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [totalPages, setTotalPages] = useState(null);
  const [content, setContent] = React.useState([]);

  const filteredData = [...content].filter((row) =>
    Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset current page when the search query changes
  };

  const paginatedData = [...filteredData].slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const edit = (channel_id) => {
    navigate("/empower/edit-channel/" + channel_id);
  };
  const delChannel = (channel_id) => {
    Swal.fire({
      text: `Are you sure you want to delete the Channel?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      allowOutsideClick: false,
    }).then(async (res) => {
      if (res.isConfirmed) {
        let apiRes = await deleteChannel(channel_id);
        console.log(apiRes);
        if (apiRes.status === "S") {
          Swal.fire({
            text: `Channel deleted`,
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            fetchData();
          });
        }
      }
    });
  };

  const fetchData = async () => {
    let apiData = await getChannels();
    apiData.status === "S" ? setContent(apiData.result_info) : setContent([]);
    apiData.status === "S"
      ? setTotalPages(Math.ceil([...apiData.result_info].length / pageSize))
      : setTotalPages(0);
  };

  useEffect(() => {
    (async () => {
      let apiData = await getChannels();
      apiData.status === "S" ? setContent(apiData.result_info) : setContent([]);
      apiData.status === "S"
        ? setTotalPages(Math.ceil([...apiData.result_info].length / pageSize))
        : setTotalPages(0);
    })();
  }, [navigate]);

  return (
    <div className="bootstrap-content manageContainer masterdata-page">
      <h5 className="heading">Manage Channels</h5>
      <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
        <div className="search-field">
          <span ref={labelRef} onClick={() => {
            if (inputRef.current) {
              inputRef.current.focus();
            }
          }} className={searchQuery ? 'float' : ''}>Search</span>
          <input ref={inputRef} type={"text"} className='w-100'
            onFocus={() => {
              if (labelRef.current) {
                labelRef.current.className = "float"
              }
            }}
            onBlur={() => {
              if (searchQuery) {
                if (labelRef.current) {
                  labelRef.current.className = "float"
                }
              } else {
                if (labelRef.current) {
                  labelRef.current.className = ""
                }
              }
            }}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="masterdata-card">
        <div className="tableContainer small-table">
          <table className="table">
            <thead>
              <tr>
                <th>Channel</th>
                <th className="col-width">Actions</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData &&
                paginatedData.length > 0 &&
                paginatedData.map((row, index) => (
                  <tr key={index}>
                    <td>{row.channel_name}</td>
                    <td className="col-width">
                      <button
                        className="btn btn-dark px-4 me-2"
                        onClick={() => {
                          edit(row.review_channel_id);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-danger px-3"
                        onClick={() => {
                          delChannel(row.review_channel_id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

        </div>
        <div className="d-flex justify-content-end align-items-center table-footer">
          <button
            className="btn btn-light me-2"
            onClick={previousPage}
            disabled={currentPage === 1}
          >
            <MdChevronLeft size={20} />
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            className="btn btn-light ms-2"
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            <MdChevronRight size={20} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ManageChannels;
