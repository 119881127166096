import React, { useEffect, useState } from 'react'
import "./sendinvites.scss"
import { getAccountChannels, sendSingleInvite } from '../../../Api';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function SendInvites() {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null);
    const [channels, setChannels] = useState(null);
    const [content, setContent] = useState({ first_name: '', last_name: '', email: '', account_channel_id: '', channel_name: '' })

    const submit = async () => {
        Swal.fire({
            html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
            customClass: { popup: 'loader' },
            showConfirmButton: false,
            heightAuto: false
        })
        let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        if (!content.account_channel_id) {
            Swal.fire({
                text: 'Please select a Channel!',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!content.email) {
            Swal.fire({
                text: 'Email required!',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else if (!emalval.test(content.email)) {
            Swal.fire({
                text: 'Please enter valid Email',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 1500
            });
        } else {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            let data = {
                account_correl_id: userData.correl_id,
                account_name: userData.company_name,
                account_channel_id: content.account_channel_id,
                channel_name: content.channel_name,
                first_name: content.first_name,
                last_name: content.last_name,
                email: content.email.toLowerCase()
            }
            let res = await sendSingleInvite(data);
            if (res.status === 'S') {
                Swal.fire({
                    text: 'Success',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    heightAuto: false
                }).then(() => {
                    navigate('/grow/recipients')
                })
            } else if (res.result_code === 203) {
                Swal.fire({
                    text: (res && res.message) ? res.message : 'Limit Exceeded',
                    icon: 'warning',
                    iconColor: '#000',
                    showConfirmButton: false,
                    timer: 2000,
                    heightAuto: false
                })
            } else {
                Swal.close()
            }
        }
    }
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };
    useEffect(() => {
        (async () => {
            let userData = JSON.parse(localStorage.getItem('userData') || '{}');
            setUserInfo(userData);
            let res = await getAccountChannels(userData.correl_id);
            if (res.status === 'S') {
                setChannels(res.result_info)
            }
        })()
    }, [])
    return (
        <div className='invites-page'>
            {/* {!showSingleInvite && !showBulkInvites && <>
                <div className="invitesContainer">
                    <div className="invites-card d-flex flex-column justify-content-center align-items-center">
                        <h6 className='send-invites'>Send Invites</h6>
                        <div className='mb-3'>
                            <button className="btn btn-dark px-lg-4 rounded-pill" onClick={handleSingleInviteClick}>
                                Send Single Invite
                            </button>
                        </div>
                        <div>
                            <button className="btn btn-dark px-lg-4 rounded-pill" onClick={handleBulkInviteClick}>
                                Send Bulk Invitations
                            </button>
                        </div>

                    </div>
                </div>
            </>} */}
            {/* {showSingleInvite && */}
            <div className='invites-card d-flex flex-column justify-content-center align-items-center'>
                <div className="w-100">
                    <h6 className='single-invite'>Single Email Invite</h6>
                    {/* <p>Invite one of your customers, by email/phone or both</p> */}
                    <p>Please fill the below details</p>

                    <div className='width-50 mx-auto'>
                        <div className="form-group w-100">
                            <select name="channel" className='form-select' placeholder="Channel"
                                onChange={(ev) => {
                                    let val = ev.target.value;
                                    let selectedChannel = (val && channels && channels.length > 0) ? channels.filter((channel) => (channel.account_channel_id === +val)) : [{ channel_name: '' }];
                                    console.log(selectedChannel)
                                    setContent({ ...content, account_channel_id: ev.target.value, channel_name: selectedChannel[0].channel_name })
                                }}
                            >
                                <option value="">-- Select Channel --</option>
                                {channels && channels.map((channel) => <option key={channel.account_channel_id} value={channel.account_channel_id}>{channel.channel_name}</option>)}
                            </select>
                        </div>
                        <div className="form-group w-100">
                            <input type="text" name="firstName" value={content.first_name} onChange={(ev) => { setContent({ ...content, first_name: ev.target.value }) }} className='w-100' placeholder="First Name" autoComplete='goodreviews' />
                        </div>
                        <div className="form-group w-100">
                            <input type="text" name="lastName" value={content.last_name} onChange={(ev) => { setContent({ ...content, last_name: ev.target.value }) }} className='w-100' placeholder="Last Name" autoComplete='goodreviews' />
                        </div>
                        <div className="form-group w-100">
                            <input type="text" name="TargetEmail" value={content.email} onChange={(ev) => { setContent({ ...content, email: ev.target.value }) }} className='w-100' placeholder="Email" autoComplete='goodreviews' />
                        </div>
                        <div className="form-group w-100 mt-3">
                            <label className='text-center'>
                                <input type="checkbox" onChange={handleCheckboxChange} required /> I certify that all recipients have opted in to receive these communications, <br /> I further certify that I am an authorized representative of {userInfo && userInfo.company_name ? userInfo.company_name : ''}.
                            </label>
                        </div>
                        <button className="btn btn-dark px-4 rounded-pill mt-3" disabled={!isChecked} onClick={() => { submit() }}>Send</button>
                    </div>
                    {/* <button className='btn btn-light px-4 rounded-pill mt-3' onClick={() => setShowSingleInvite(false)}>Back</button> */}
                </div>
            </div>
            {/* } */}
        </div>
    )
}

export default SendInvites
