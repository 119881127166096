import React from 'react'
import "./support.scss"

function Support() {
    const handleEmailClick = () => {
        window.location.href = 'mailto:googlereviews@iTester.com';
    };
    return (
        <div className='support-page'>
            <div className="support-container">
                <h6 className='heading'>Support</h6>
                <div className="row">
                    <div className="col-12">
                        <div className="support-card">
                            <p className='mb-0'>Any queries/support please send an email to</p>
                            <span
                                onClick={handleEmailClick}
                                className='mail-link'
                            >
                                googlereviews@iTester.com
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Support
