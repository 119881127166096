import React from 'react'
import './footer.scss'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div className='bootstrap-content footer-page'>
            <footer className='footer'>
                <div className="container p-4">
                    <div className="row">
                        <div className="col-12 col-lg-3 mt-3">
                            <div className="d-flex align-items-center mb-3">
                                {/* <h6 className='footer-logo-text mb-0'>Good Reviews</h6> */}
                                <img src={require('../../assets/logo.png')} alt="" className='footer-logo' />
                            </div>
                            <div className="d-flex justify-content-lg-end align-items-center mb-3 mr-padding">
                                <img src={require('../../assets/itester.png')} alt="" className='itesterimage' />
                                <p className='mb-0 image-subtext'>An <strong>iTester</strong>  Product  </p>
                            </div>
                            <div className="d-flex justify-content-lg-end align-items-center mb-3 mr-padding">
                                <img src={require('../../assets/iso-1.png')} alt="" className='iso-image-1' />
                                <img src={require('../../assets/iso-2.png')} alt="" className='iso-image-2' />
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <h6 className='footer-heaidng'>Navigation</h6>
                            <ul className='p-0'>
                                <li className='list-style'><p><Link to="/">Home</Link></p></li>
                                {/* <li className='list-style'><p>Google Businesses</p></li> */}
                                <li className='list-style'><p><Link to="/pricing">Pricing</Link></p></li>
                                {/* <li className='list-style'><p>Services</p></li>
                                <li className='list-style'><p>Portfolio</p></li>
                                <li className='list-style'><p>Blog</p></li> */}
                                <li className='list-style'><p><Link to="/contact-us">Contact us</Link></p></li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-3">
                            <h6 className='footer-heaidng'>Legal</h6>
                            <ul className='p-0'>
                                <li className='list-style'><p><Link to="/terms-and-conditions">Terms & Conditions</Link></p></li>
                                <li className='list-style'><p><Link to="/privacy-policy">Privacy Policy</Link></p></li>
                                <li className='list-style'><p><Link to="/cookie-policy">Cookie Policy</Link></p></li>
                                <li className='list-style'><p><Link to="/disclaimer">Disclaimer</Link></p></li>
                            </ul>
                        </div>
                        <div className="col-12 col-lg-3">
                            <h6 className='footer-heaidng'>Get In Touch</h6>
                            <ul className='p-0'>
                                <li className='list-style'><p><Link to="tel:1 (855) 834-2399">1 (855) 834-2399</Link></p></li>
                                <li className='list-style'><p><Link to="mailto:hello@goodreviews.co">hello@goodreviews.co</Link></p></li>
                                <li className='list-style'><p>447 Broadway 2nd Floor, New York,<br className='d-lg-block d-none' />  NY 10013, United States</p></li>
                                {/* <li className='list-style'><p>Mon.-Thurs. <br className='d-lg-block d-none' /> 9 a.m.-5 p.m. PT <br className='d-lg-block d-none' /> Fri. 9am.-1 p.m. PT</p></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="d-flex justify-content-center flex-wrap gap-30">
                                <img src={require('../../assets/facebook.png')} alt="" className='footer-bottom-image' />
                                <img src={require('../../assets/facebook-messanger.png')} alt="" className='footer-bottom-image' />
                                <img src={require('../../assets/instagram.png')} alt="" className='footer-bottom-image' />
                                <img src={require('../../assets/youtube.png')} alt="" className='footer-bottom-image' />
                                <img src={require('../../assets/whatsapp.png')} alt="" className='footer-bottom-image' />
                                <img src={require('../../assets/linkedin.png')} alt="" className='footer-bottom-image' />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer
